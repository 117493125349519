import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrayText } from '../../shared/TextComponents'
import { Vendor } from '../../../utils/vendors'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { CostAnomalySettings } from './CostAnomalySettings'
import { useNavigate } from 'react-router-dom'
import { PingType, Tab, TabLevel } from '../../shared/tabs/Tab'
import { TabOptionsWrapper } from '../../shared/tabs/TabSharedComponents'
import { HelpTooltip, HelpTooltipInfoBlock, HelpTooltipModal } from '../../shared/tooltips/HelpTooltip'
import { ModalContext } from '../../../state/context/ModalContext'
import { Button, ButtonSize } from '../../shared/buttons/Button'
import moneyIcon from '../../../assets/svg/objects/cash-bill.svg'
import chartIcon from '../../../assets/svg/objects/ticked-bar-chart.svg'
import notificationIcon from '../../../assets/svg/sidebar/notifications-icon.svg'
import { getCurrency } from '../../../utils/Currency'
import { getNotificationProject, getNotificationService } from '../../../state/storage'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { NestedPageHeader } from '../../shared/NestedPageHeader'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { Layout, SubLayoutContentWrapper } from '../../../layout/Layout'

export enum CostAnomalySettingsTab {
  PROJECTS,
  SERVICES
}

interface NotificationSettingsProps {
  selectedVendor: Vendor
}

export const NotificationSettings = ({ selectedVendor }: NotificationSettingsProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<CostAnomalySettingsTab>(CostAnomalySettingsTab.PROJECTS)
  const [projectSearchText, setProjectSearchText] = useState('')
  const [serviceSearchText, setServiceSearchText] = useState('')

  const handleVendorChange = (vendor: Vendor) => {
    navigate(`/settings/notifications/${vendor.toLowerCase()}`)
  }

  const openModal = () => {
    setModal({
      header: t('settings.notifications.helpModal.header'),
      body: (
        <HelpTooltipModal
          contentRows={[
            <HelpTooltipInfoBlock iconPath={notificationIcon} text={t('settings.notifications.helpModal.text_1')} />,
            <HelpTooltipInfoBlock iconPath={chartIcon} text={t('settings.notifications.helpModal.text_2')} />,
            <HelpTooltipInfoBlock
              iconPath={moneyIcon}
              text={t('settings.notifications.helpModal.text_3', {
                defaultMonthlyImpact: userSettings.defaultCostAnomalyLimits.increaseMonthlyImpact,
                currency: getCurrency(userSettings.currency).symbolLong
              })}
            />
          ]}
          actions={<Button value={t('common.gotIt')} size={ButtonSize.XSMALL} clickHandler={() => setModal(null)} />}
        />
      )
    })
  }

  if (!selectedVendor)
    return (
      <Layout type={'sub'}>
        <NestedPageHeader
          mainHeading={t('settings.notifications.heading')}
          subHeading={<GrayText>{t('settings.notifications.subHeading')}</GrayText>}
          actions={<HelpTooltip clickHandler={openModal} />}
        />
        <SubLayoutContentWrapper>
          <MissingDataNotification displayText={t('settings.notifications.noAccess')} />
        </SubLayoutContentWrapper>
      </Layout>
    )

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={t('settings.notifications.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('settings.notifications.subHeading')}</GrayText>}
        actions={<HelpTooltip clickHandler={openModal} />}
      />
      <SubLayoutContentWrapper>
        <VendorTabs selectedVendor={selectedVendor} handleSelection={handleVendorChange} />
        <div className={'flex flex-col gap-8'}>
          <CostAnomalySettingsTabs
            selectedVendor={selectedVendor}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            projectSearchText={projectSearchText}
            serviceSearchText={serviceSearchText}
          />
          <CostAnomalySettings
            selectedVendor={selectedVendor}
            selectedTab={selectedTab}
            projectSearchText={projectSearchText}
            setProjectSearchText={setProjectSearchText}
            serviceSearchText={serviceSearchText}
            setServiceSearchText={setServiceSearchText}
          />
        </div>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

interface CostAnomalySettingsTabsProps {
  selectedVendor: Vendor
  selectedTab: CostAnomalySettingsTab
  setSelectedTab: (tab: CostAnomalySettingsTab) => void
  projectSearchText: string
  serviceSearchText: string
}

const CostAnomalySettingsTabs = ({
  selectedVendor,
  selectedTab,
  setSelectedTab,
  projectSearchText,
  serviceSearchText
}: CostAnomalySettingsTabsProps) => {
  const { t } = useTranslation()
  const notificationProject = getNotificationProject()
  const notificationService = getNotificationService()

  return (
    <TabOptionsWrapper level={TabLevel.SECONDARY}>
      <Tab
        level={TabLevel.SECONDARY}
        styles={'max-w-40'}
        tabLabel={t(`vendors.${selectedVendor}.projectPhrase_other`)}
        tabValue={CostAnomalySettingsTab.PROJECTS}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(CostAnomalySettingsTab.PROJECTS)}
        ping={
          selectedTab !== CostAnomalySettingsTab.PROJECTS &&
          (!!notificationProject || projectSearchText !== '') &&
          PingType.POINT
        }
      />
      <Tab
        level={TabLevel.SECONDARY}
        styles={'max-w-40'}
        tabLabel={t('common.service_other')}
        tabValue={CostAnomalySettingsTab.SERVICES}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(CostAnomalySettingsTab.SERVICES)}
        ping={
          selectedTab !== CostAnomalySettingsTab.SERVICES &&
          (!!notificationService || serviceSearchText !== '') &&
          PingType.POINT
        }
      />
    </TabOptionsWrapper>
  )
}

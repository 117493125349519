import React, { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { FilterOption } from '../../components/shared/filters/FilterSelect'
import { changeCurrentTenant, getSystemAdminTenants } from '../../api/system-admin'
import Select from 'react-select'
import { selectMenuListStyles, selectOptionStyles } from '../../components/shared/filters/ReactSelectStyles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCostAnomalyNotifications } from '../../api/notifications'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { CustomIcon } from '../../components/shared/CustomIcon'
import swap from '../../assets/svg/actions/swap.svg'
import { useTranslation } from 'react-i18next'
import { getUserContextData } from '../../api/settings/profile'
import { ModalContext } from '../../state/context/ModalContext'

export const TenantMenu = () => {
  const { t } = useTranslation()
  const { authInfo, setAuthInfo, setUserSettings, tenants, setTenants, setIntegrationStates } =
    useContext(UserInfoContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { setModal } = useContext(ModalContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const pathParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentTenant, setCurrentTenant] = useState<FilterOption>({
    value: authInfo.tenant.id,
    label: authInfo.tenant.name
  })
  const currentLocation = location.pathname

  useEffect(() => {
    !tenants.length && getSystemAdminTenants(createCancelToken().token).then(setTenants).catch(handleError)
  }, [createCancelToken, handleError])

  const handleTenantChange = (tenant: FilterOption) => {
    const cancelToken = createCancelToken()
    changeCurrentTenant(cancelToken.token, tenant.value)
      .then(() => {
        getUserContextData(cancelToken.token).then(resp => {
          setAuthInfo(resp.authInfo)
          setUserSettings(resp.userSettings)
          setIntegrationStates(resp.integrationStates)

          setCurrentTenant({ value: resp.authInfo.tenant.id, label: resp.authInfo.tenant.name })
          navigate('/*')
          setModal(null)
          toast.dismiss()
          setTimeout(() => {
            if (JSON.stringify(pathParams) !== JSON.stringify({})) {
              const paramNeutralPath = currentLocation.split('/').slice(0, -1).join('/')
              navigate(paramNeutralPath)
            } else navigate(currentLocation)
          }, 15)
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
        })
      })
      .catch(e => {
        handleError(e)
        setCurrentTenant({ value: authInfo.tenant.id, label: authInfo.tenant.name })
      })
  }

  return (
    <div className={'group px-2'}>
      <Select
        unstyled
        styles={{ menu: base => ({ ...base, zIndex: 1000 }) }}
        classNames={{
          control: () => 'flex items-center hover:cursor-pointer text-90',
          menuList: () => selectMenuListStyles + ` text-90 min-w-60 max-h-75 mt-2 py-1.5 overflow-y-auto`,
          option: state =>
            selectOptionStyles + `${(state.isSelected || state.isFocused) && ' bg-gray-600 text-gray-50'}`
        }}
        defaultValue={currentTenant}
        value={currentTenant}
        options={tenants.map(tenant => ({ value: tenant.id, label: tenant.name }))}
        onChange={selected => handleTenantChange(selected as FilterOption)}
        components={{
          DropdownIndicator: () => (
            <div className={'px-2'}>
              <CustomIcon
                path={swap}
                styles={'w-5 h-5 bg-gray-50 group-hover:scale-105'}
                tooltipText={t('topNav.swapTenant')}
                tooltipStyles={'w-max'}
              />
            </div>
          )
        }}
      />
    </div>
  )
}

import React, { useState } from 'react'
import Select, { DropdownIndicatorProps, GroupBase, Props, SingleValue } from 'react-select'
import tw from 'twin.macro'
import { selectMenuListStyles, selectOptionStyles } from './filters/ReactSelectStyles'
import { CustomIcon } from './CustomIcon'
import meatBalls from '../../assets/svg/actions/meatballs-menu.svg'
import { CheckboxOption } from './buttons/CheckboxOption'
import { CapitalizedText } from './TextComponents'
import styled from 'styled-components'
import { RadioButton } from './buttons/RadioButton'
import caret from '../../assets/svg/directional/caret-heavy.svg'

export type MeatballsMenuOption = {
  label: string
  value?: string
  isDisabled?: boolean
  clickHandler?: () => void
  group?: string
} & (
  | {
      isCheckbox?: boolean
      checked?: boolean
    }
  | {
      isRadio?: boolean
      type?: 'small' | 'default' | 'filter' | 'secondary' | 'container'
      checked?: boolean
    }
  | {
      nestedOptions?: boolean
    }
)

interface MeatballsMenuProps extends Props {
  options: MeatballsMenuOption[]
  size?: 'small' | 'default'
  iconSize?: string
}

export const MeatballsMenu = (props: MeatballsMenuProps) => {
  const { iconSize = 'w-8 h-8', size = 'default' } = props
  const [showingNested, setShowingNested] = useState(false)
  const options = props.options.map(option => ({
    ...option,
    value: option.value === undefined ? option.label : option.value
  }))

  interface Radiogroup {
    index: number
    label: string
    group?: string
  }

  const firstOfRadioGroups: Radiogroup[] = []
  options.forEach((option, index) => {
    if (!firstOfRadioGroups.some(item => 'group' in option && item.group === option.group)) {
      'group' in option &&
        firstOfRadioGroups.push({
          index: index,
          label: option.label,
          group: option.group
        })
    }
  })
  const labels = firstOfRadioGroups.filter(item => item.index > 0).map(o => o.label)

  const formatOptionLabel = (data: MeatballsMenuOption) => {
    if ('isCheckbox' in data && data.isCheckbox)
      return (
        <OptionWrapper>
          <CheckboxOption
            label={data.label}
            type={'secondary'}
            capitalize={true}
            clickHandler={() => undefined}
            checked={!!data.checked}
            disabled={data.isDisabled}
          />
        </OptionWrapper>
      )
    if ('isRadio' in data) {
      return (
        <OptionWrapper>
          <RadioButton
            label={data.label}
            onChange={() => undefined}
            checked={!!data.checked}
            disabled={data.isDisabled}
            type={'type' in data ? data.type : 'filter'}
          />
        </OptionWrapper>
      )
    }
    if ('nestedOptions' in data && data.nestedOptions) {
      return (
        <OptionWrapper className={'group'}>
          <CapitalizedText>{data.label}</CapitalizedText>
          <CustomIcon path={caret} styles={'-rotate-90 w-5 h-5 bg-gray-300 group-hover:bg-gray-50'} />
        </OptionWrapper>
      )
    }
    if (showingNested && data.label === options[0].label) {
      return (
        <OptionWrapper className={'group'}>
          <CustomIcon path={caret} styles={'rotate-90 w-5 h-5 bg-gray-300 group-hover:bg-gray-50'} />
          <CapitalizedText className={'w-full text-left'}>{data.label}</CapitalizedText>
        </OptionWrapper>
      )
    }
    return <CapitalizedText>{data.value}</CapitalizedText>
  }

  const DropdownIndicator = (
    props: DropdownIndicatorProps<MeatballsMenuOption, false, GroupBase<MeatballsMenuOption>>
  ) => {
    return (
      <CustomIcon
        path={meatBalls}
        styles={`ml-1 ${size === 'small' ? 'w-7 h-7' : iconSize} bg-gray-200 group-hover:bg-gray-50 ${props.selectProps.menuIsOpen && 'bg-gray-50'}`}
      />
    )
  }

  return (
    <Select
      unstyled
      classNames={{
        control: () =>
          ` border-none bg-transparent shadow-none w-max ${size === 'small' && 'text-90'} ${!props.isDisabled && 'hover:cursor-pointer'}`,
        valueContainer: () => 'w-0 m-0',
        menuList: () => selectMenuListStyles + ' min-w-60 w-max max-w-85',
        option: option =>
          selectOptionStyles +
          ` text-left first-letter:capitalize ${option.isDisabled && ' text-gray-200 hover:cursor-not-allowed'} ${labels.includes(option.label) && 'mt-3'}`
      }}
      styles={{ menu: base => ({ ...base, zIndex: 15 }) }}
      isDisabled={props.isDisabled}
      closeMenuOnSelect={false}
      isSearchable={false}
      options={options}
      formatOptionLabel={formatOptionLabel}
      isMulti={false}
      isRtl={true}
      onChange={(selected: SingleValue<MeatballsMenuOption>) => {
        if (selected && !selected.isDisabled) {
          selected.clickHandler && selected.clickHandler()
          'nestedOptions' in selected && selected.nestedOptions && setShowingNested(true)
          showingNested && selected.label === options[0].label && setShowingNested(false)
        }
      }}
      components={{ DropdownIndicator }}
    />
  )
}

const OptionWrapper = styled.div`
  ${tw`flex w-full justify-between items-center gap-2.5`}
  direction: ltr !important;
`

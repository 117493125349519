import { FilterOption, GroupedOptions } from '../../components/shared/filters/FilterSelect'
import { client } from '../client'
import { CancelToken } from 'axios'
import { CurrencyId } from '../../utils/Currency'
import { Vendor } from '../../utils/vendors'

export const getTenantCostAnomalyRules = async (cancelToken: CancelToken): Promise<TenantCostAnomalyRule[]> => {
  return await client
    .get<TenantCostAnomalyRule[]>(`admin/alerts/costs`, { cancelToken })
    .then(resp =>
      resp.data.map(rule => ({ ...rule, createdAt: new Date(rule.createdAt), updatedAt: new Date(rule.updatedAt) }))
    )
}

export const getCostAnomalyTargetOptions = async (cancelToken: CancelToken): Promise<CostAnomalyTargetOptions> => {
  return await client
    .get<CostAnomalyTargetOptions>(`admin/alerts/costs/target-options`, {
      cancelToken
    })
    .then(resp => resp.data)
}

export const createTenantCostAnomalyRule = async (
  rule: CostAnomalyRuleForm,
  cancelToken: CancelToken
): Promise<TenantCostAnomalyRule[]> => {
  return await client
    .post<TenantCostAnomalyRule[]>(`admin/alerts/costs`, rule, {
      cancelToken
    })
    .then(resp =>
      resp.data.map(rule => ({ ...rule, createdAt: new Date(rule.createdAt), updatedAt: new Date(rule.updatedAt) }))
    )
}

export const getTenantCostAnomalyRule = async (
  ruleId: string,
  cancelToken: CancelToken
): Promise<TenantCostAnomalyRule> => {
  return await client.get<TenantCostAnomalyRule>(`admin/alerts/costs/${ruleId}`, { cancelToken }).then(resp => ({
    ...resp.data,
    createdAt: new Date(resp.data.createdAt),
    updatedAt: new Date(resp.data.updatedAt)
  }))
}

export const editTenantCostAnomalyRule = async (
  ruleId: string,
  rule: TenantCostAnomalyRule,
  cancelToken: CancelToken
): Promise<TenantCostAnomalyRule> => {
  return await client
    .put<TenantCostAnomalyRule>(`admin/alerts/costs/${ruleId}`, rule, {
      cancelToken
    })
    .then(resp => ({
      ...resp.data,
      createdAt: new Date(resp.data.createdAt),
      updatedAt: new Date(resp.data.updatedAt)
    }))
}

export const deleteTenantCostAnomalyRule = async (
  ruleId: string,
  cancelToken: CancelToken
): Promise<TenantCostAnomalyRule[]> => {
  return await client
    .delete<TenantCostAnomalyRule[]>(`admin/alerts/costs/${ruleId}`, {
      cancelToken
    })
    .then(resp =>
      resp.data.map(rule => ({
        ...rule,
        createdAt: new Date(rule.createdAt),
        updatedAt: new Date(rule.updatedAt)
      }))
    )
}

export interface CostAnomalyTargetOptions {
  projects: GroupedOptions[]
  services: GroupedOptions[]
}

export interface CostAnomalyRuleForm {
  name: string | null
  emailAddresses: string[]
  decrease: CostAnomalyLimits
  increase: CostAnomalyLimits
  currency: CurrencyId
  projects: FilterOption[]
  services: FilterOption[]
}

export interface CostAnomalyLimits {
  percent: number
  monthlyImpact: number
}

export interface CostAnomalyProject {
  vendor: Vendor
  value: string
  label: string
  services: FilterOption[]
}

export interface TenantCostAnomalyRule {
  ruleId: string
  name: string
  decrease: CostAnomalyLimits
  increase: CostAnomalyLimits
  currency: CurrencyId
  emailAddresses: string[]
  createdAt: Date
  updatedAt: Date
  targets: CostAnomalyProject[]
}

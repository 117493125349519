import { ChangeType } from '../../api/notifications'
import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Button, ButtonSize, ButtonStyle } from './buttons/Button'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useBlurOnEnter } from '../../hooks/useBlurOnEnter'

interface LimitInputProps {
  id?: string
  value: number | null
  disabled?: boolean
  onChange?: (value?: number) => void
  onBlur: (value?: number) => void
  min: number
  max: number
  type: ChangeType
  highlight?: boolean
}

export const LimitInput = ({
  id,
  value,
  disabled,
  onChange,
  onBlur,
  min,
  max,
  type,
  highlight = false
}: LimitInputProps) => {
  const [currValue, setCurrValue] = useState(value)
  const [focused, setFocused] = useState(false)
  const wrapperRef = useRef(null)

  useBlurOnEnter(id)

  useOnClickOutside(wrapperRef, () => {
    setFocused(false)
  })

  useEffect(() => {
    if (!disabled) {
      currValue && (isNaN(currValue) || currValue === 0) && setCurrValue(min)
      currValue && !focused && value !== currValue && onBlur(currValue)
    }
  }, [focused])

  return (
    <NumberInputWrapper ref={wrapperRef} focused={focused || highlight}>
      <Button
        value={'-'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={disabled || (currValue !== null && currValue <= min)}
        clickHandler={e => {
          e.preventDefault()
          e.stopPropagation()
          setFocused(true)
          currValue && setCurrValue(currValue - 1)
        }}
      />
      <NumberInput
        id={id}
        value={currValue || ''}
        onFocus={() => setFocused(true)}
        onBlur={e => {
          if (!disabled) {
            const inputValue = isNaN(e.target.valueAsNumber) ? min : e.target.valueAsNumber
            inputValue > max ? setCurrValue(max) : inputValue < min ? setCurrValue(min) : setCurrValue(inputValue)
            setFocused(false)
          }
        }}
        onChange={e => {
          if (!disabled) {
            const inputValue = isNaN(e.target.valueAsNumber) ? min : e.target.valueAsNumber
            setCurrValue(type === ChangeType.DECREASING ? -Math.abs(inputValue) : Math.abs(inputValue))
            onChange && onChange(type === ChangeType.DECREASING ? -Math.abs(inputValue) : Math.abs(inputValue))
          }
        }}
        type={'number'}
        min={min}
        max={max}
      />
      <Button
        value={'+'}
        buttonStyle={ButtonStyle.GHOST}
        size={ButtonSize.XSMALL}
        disabled={disabled || (currValue !== null && currValue >= max)}
        clickHandler={e => {
          e.preventDefault()
          e.stopPropagation()
          setFocused(true)
          currValue && setCurrValue(currValue + 1)
        }}
      />
    </NumberInputWrapper>
  )
}

interface NumberInputWrapperProps {
  focused: boolean
  disabled?: boolean
}

const NumberInputWrapper = styled.div<NumberInputWrapperProps>`
  ${tw`flex w-fit items-center justify-center gap-2 border rounded py-0.5`}

  ${({ focused, disabled }) =>
    focused
      ? tw`border-transparent outline-primary-500`
      : disabled
        ? tw`border-gray-400/30 cursor-not-allowed hover:border-gray-400/30`
        : tw`border-gray-400/50 hover:border-gray-400`}
`

export const NumberInput = styled.input`
  ${tw`min-w-14 bg-transparent text-gray-100 shadow-none outline-none rounded m-0 p-0 h-fit text-center focus:font-semibold focus:text-gray-50 focus:shadow-none`}
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
  }
`

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomIcon, IconType } from './CustomIcon'
import caret from '../../assets/svg/directional/caret.svg'
import { Heading } from './TextComponents'
import { Button, ButtonStyle, ButtonType } from './buttons/Button'

interface NestedPageHeaderProps {
  navigateBack?: boolean
  backButtonPath?: string
  mainHeading?: React.ReactNode
  normalCase?: boolean
  subHeading?: React.ReactNode
  capitalize?: boolean
  actions?: React.ReactNode
}

export const NestedPageHeader = ({
  navigateBack,
  backButtonPath,
  capitalize,
  mainHeading,
  subHeading,
  actions
}: NestedPageHeaderProps) => {
  const navigate = useNavigate()

  return (
    <div className={'flex py-9 w-full border-b border-gray-500 justify-between items-center gap-4 md:gap-8'}>
      <div className={'flex items-center gap-4 w-full md:gap-8'}>
        {(navigateBack || backButtonPath) && (
          <Button
            type={ButtonType.ICON}
            buttonStyle={ButtonStyle.GHOST}
            clickHandler={() => (navigateBack ? navigate(-1) : backButtonPath && navigate(backButtonPath))}
            value={<CustomIcon styles={'w-6 h-6 rotate-90 bg-gray-50'} iconType={IconType.VECTOR} path={caret} />}
          />
        )}
        <div className={'flex flex-col w-full gap-1'}>
          {mainHeading && <Heading normalCase={!capitalize}>{mainHeading}</Heading>}
          {subHeading}
        </div>
      </div>
      {actions && <div className={'flex flex-row w-max gap-4 items-center justify-end'}>{actions}</div>}
    </div>
  )
}

import { TFunction, useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { Navigate, Route, Routes } from 'react-router-dom'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { UserProfile } from '../../components/settings/profile/UserProfile'
import { featuresFlags } from '../../state/featuresFlags'
import { NotificationSettings } from '../../components/settings/notifications/NotificationSettings'
import { Vendors } from '../../utils/vendors'
import { AuthInfo } from '../../api/auth'
import { NestedRoutesProps, PageNotFound } from './SpotterRoutes'

export const SettingsRoutes = ({ basePath }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const links: NavbarLink[] = getSettingsSubLinks(authInfo, t)
  const notificationsPath = links.find(link => link.id === NavbarLinkId.SETTINGS_NOTIFICATIONS)?.path

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={`${basePath}${links[0].path}`} />} />
      <Route path={`/profile`} element={<UserProfile />} />
      {featuresFlags.notifications && authInfo.notificationsAccess && (
        <>
          <Route
            path={notificationsPath}
            element={
              authInfo.awsAccess && integrationStates.aws ? (
                <Navigate to={`${basePath}${notificationsPath}/aws`} />
              ) : authInfo.azureAccess && integrationStates.azure ? (
                <Navigate to={`${basePath}${notificationsPath}/azure`} />
              ) : authInfo.gcpAccess && integrationStates.gcp ? (
                <Navigate to={`${basePath}${notificationsPath}/gcp`} />
              ) : (
                <Navigate to={`${basePath}/profile`} />
              )
            }
          />
          {Vendors.map(vendor => (
            <Route
              key={vendor}
              path={`${notificationsPath}/${vendor.toLowerCase()}`}
              element={<NotificationSettings selectedVendor={vendor} />}
            />
          ))}
        </>
      )}
      <Route path={'/*'} element={<PageNotFound />} />
    </Routes>
  )
}

export const getSettingsSubLinks = (authInfo: AuthInfo, t: TFunction): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.SETTINGS_PROFILE,
      label: t('subNav.settings.profile'),
      path: '/profile',
      display: true
    },
    {
      id: NavbarLinkId.SETTINGS_NOTIFICATIONS,
      label: t('subNav.settings.notifications'),
      path: '/notifications',
      display: featuresFlags.notifications && authInfo.notificationsAccess
    }
  ]
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import StateProvider from './state/StateProvider'
import './index.css'
import { Env } from './utils/env'
import { BrowserRouter as Router } from 'react-router-dom'

if (process.env.REACT_APP_ENV === Env.PROD) {
  const hotJarScript = document.createElement('script')
  hotJarScript.text =
    '(function(h,o,t,j,a,r){\n' +
    '        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n' +
    '        h._hjSettings={hjid:2676331,hjsv:6};\n' +
    "        a=o.getElementsByTagName('head')[0];\n" +
    "        r=o.createElement('script');r.async=1;\n" +
    '        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
    '        a.appendChild(r);\n' +
    "    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"

  const googleAnalyticsScript_1 = document.createElement('script')
  googleAnalyticsScript_1.src = 'https://www.googletagmanager.com/gtag/js?id=G-E6KP94VE4B'

  const googleAnalyticsScript_2 = document.createElement('script')
  googleAnalyticsScript_2.text =
    'window.dataLayer = window.dataLayer || [];\n' +
    '  function gtag(){dataLayer.push(arguments);}\n' +
    "  gtag('js', new Date());\n" +
    "  gtag('config', 'G-E6KP94VE4B');"

  document.head.appendChild(hotJarScript)
  document.head.appendChild(googleAnalyticsScript_1)
  document.head.appendChild(googleAnalyticsScript_2)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <StateProvider>
      <Router
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true
        }}
      >
        <App />
      </Router>
    </StateProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

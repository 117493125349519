import React from 'react'
import styled from 'styled-components'
import logo from '../../../assets/svg/brand/cloud2-logo.svg'
import plainLogo from '../../../assets/svg/brand/plain-logo.svg'
// workaround for CRA 5 bug https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import animatedLogo from '!file-loader!../../../assets/svg/brand/cloud2-animated-logo.svg'
// eslint-disable-next-line import/no-webpack-loader-syntax
import plainAnimatedLogo from '!file-loader!../../../assets/svg/brand/plain-animated-logo.svg'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import spotterGlow from '../../../assets/svg/brand/spotter-glow.svg'

interface LogoProps {
  animated: boolean
  id?: string
}

export const Logo = ({ animated, id }: LogoProps) => {
  return <LogoImg id={id} src={animated ? animatedLogo : logo} alt={'logo'} />
}

export const SpotterLogo = ({ id, animated }: LogoProps) => {
  const { t } = useTranslation()
  return (
    <LogoWrapper id={id}>
      <LogoImg src={animated ? plainAnimatedLogo : plainLogo} alt={'logo'} style={{ maxWidth: '70%' }} />
      <img src={spotterGlow} alt={t('projectName')} style={{ minWidth: '210px', marginTop: '-4px' }} />
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center w-44`}
`

const LogoImg = styled.img`
  height: 100%;
  width: 100%;
`

export const FullScreenLogoContainer = styled.div`
  ${tw`w-40 mb-7`}
`

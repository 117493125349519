import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import React, { useContext, useState } from 'react'
import { GcpBillingAccount, GcpIntegration, GcpIntegrationData } from '../../../../api/admin/integrations/gcp'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { AdminTabContent } from '../../adminStyles'
import { ModalContext } from '../../../../state/context/ModalContext'
import { ModalActions } from '../../../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'

interface GcpIntegrationsAccountsProps {
  selectedIntegration: GcpIntegrationData
  editIntegrationAction: (name: string) => void
  editBillingAccountAction: (request: GcpBillingAccount) => void
  openAccountAddModal: (account?: GcpBillingAccount) => void
  openAccountDeleteModal: (account: GcpBillingAccount) => void
}

export const GcpIntegrationAccounts = ({
  selectedIntegration,
  editIntegrationAction,
  openAccountAddModal,
  openAccountDeleteModal,
  editBillingAccountAction
}: GcpIntegrationsAccountsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <OrganizationSection selectedIntegration={selectedIntegration} submitAction={editIntegrationAction} />
      <AdminTabContent>
        <IntegrationDetailsHeader
          header={t('admin.integrations.gcp.billingDetails')}
          addAction={() => openAccountAddModal()}
        />
        {selectedIntegration.billingAccounts.map(account => (
          <EditBillingAccountForm
            key={`${account.accountId}-${account.projectId}-${account.tableId}`}
            billingAccount={account}
            submitAction={editBillingAccountAction}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
          />
        ))}
      </AdminTabContent>
    </>
  )
}

interface OrganizationProps {
  selectedIntegration: GcpIntegration
  submitAction: (name: string) => void
}

const OrganizationSection = ({ selectedIntegration, submitAction }: OrganizationProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [defaultValues, setDefaultValues] = useState<GcpIntegration>({ ...selectedIntegration })

  const EditModalBody = () => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { isDirty, isValid, isSubmitting }
    } = useForm<GcpIntegration>({
      defaultValues: defaultValues
    })

    const onSubmit = (data: GcpIntegration) => {
      submitAction(data.name)
      setDefaultValues(data)
      reset(data)
    }

    return (
      <>
        <div className={'pb-3'}>
          <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
          <CustomInput
            {...register('name', {
              required: true,
              minLength: 1
            })}
          />
        </div>
        <ModalActions>
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => {
              reset(defaultValues)
              setModal(null)
            }}
            value={t('common.cancel')}
          />
          <Button
            type={ButtonType.FORM}
            clickHandler={handleSubmit(onSubmit)}
            value={t('common.saveChanges')}
            disabled={!isDirty || !isValid || isSubmitting}
          />
        </ModalActions>
      </>
    )
  }

  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.gcp.organization')}
        editAction={() =>
          setModal({
            header: t('admin.integrations.gcp.editOrganization'),
            body: <EditModalBody />
          })
        }
      />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.gcp.organizationId')}
              value={selectedIntegration.organizationId}
            />
            <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.name} />
          </>
        }
      />
    </AdminTabContent>
  )
}

interface EditBillingAccountProps {
  submitAction: (request: GcpBillingAccount) => void
  billingAccount: GcpBillingAccount
  openAccountAddModal: (account?: GcpBillingAccount) => void
  openAccountDeleteModal: (account: GcpBillingAccount) => void
}

const EditBillingAccountForm = ({
  submitAction,
  billingAccount,
  openAccountAddModal,
  openAccountDeleteModal
}: EditBillingAccountProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [defaultValues, setDefaultValues] = useState<GcpBillingAccount>({ ...billingAccount })

  const EditModalBody = () => {
    const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { isDirty, isValid, isSubmitting }
    } = useForm<GcpBillingAccount>({
      defaultValues: defaultValues
    })

    const onSubmit = (data: GcpBillingAccount) => {
      submitAction(data)
      setDefaultValues(data)
      reset(data)
    }

    return (
      <>
        <div className={'pb-3'}>
          <CustomLabel>{t('admin.integrations.gcp.bigQueryTableId')} *</CustomLabel>
          <CustomInput
            title={watch('tableId')}
            {...register('tableId', {
              required: true,
              minLength: 1
            })}
          />
        </div>
        <ModalActions>
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => {
              reset(defaultValues)
              setModal(null)
            }}
            value={t('common.cancel')}
          />
          <Button
            type={ButtonType.FORM}
            clickHandler={handleSubmit(onSubmit)}
            value={t('common.saveChanges')}
            disabled={!isDirty || !isValid || isSubmitting}
          />
        </ModalActions>
      </>
    )
  }

  return (
    <IntegrationDetailRowsWrapper
      hasActions={true}
      isRemoved={!!billingAccount.deletedAt}
      editAction={() => setModal({ header: t('admin.integrations.gcp.editBillingAccount'), body: <EditModalBody /> })}
      statusAction={() => {
        billingAccount.deletedAt ? openAccountAddModal(billingAccount) : openAccountDeleteModal(billingAccount)
      }}
      detailRows={
        <>
          <IntegrationDetailRow label={t('admin.integrations.accountId')} value={billingAccount.accountId} />
          <IntegrationDetailRow label={t('admin.integrations.gcp.projectId')} value={billingAccount.projectId} />
          <IntegrationDetailRow label={t('admin.integrations.gcp.bigQueryTableId')} value={billingAccount.tableId} />
          <IntegrationAccountStatusRow accountDeleteDate={billingAccount.deletedAt} />
        </>
      }
    />
  )
}

import React, { useEffect, useState } from 'react'
import { getSystemAdmins, SystemAdminBasicInfo } from '../../api/master-admin'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { useTranslation } from 'react-i18next'
import { Button, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import editIcon from '../../assets/svg/actions/edit.svg'
import { GrayText, Heading } from '../shared/TextComponents'
import { SearchInput } from '../shared/filters/SearchInput'
import { ScrollContainer } from '../shared/containers/ScrollContainer'
import { DimmedListRow } from './Shared'
import { NestedPageHeader } from '../shared/NestedPageHeader'
import { Layout, SubLayoutContentWrapper } from '../../layout/Layout'

export const MasterAdminSystemAdminList = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const navigate = useNavigate()
  const [systemAdmins, setSystemAdmins] = useState<SystemAdminBasicInfo[]>([])
  const [filteredSysAdmins, setFilteredSysAdmins] = useState<SystemAdminBasicInfo[]>([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    getSystemAdmins(createCancelToken().token).then(setSystemAdmins).catch(handleError)
  }, [createCancelToken, handleError])

  useEffect(() => {
    if (searchText !== '')
      setFilteredSysAdmins(
        systemAdmins.filter(
          sysAdmin =>
            sysAdmin.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            sysAdmin.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
            sysAdmin.emailAddress.toLowerCase().includes(searchText.toLowerCase()) ||
            sysAdmin.currentTenant.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    else setFilteredSysAdmins(systemAdmins)
  }, [searchText, systemAdmins])

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={t('masterAdmin.systemAdmins.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('masterAdmin.systemAdmins.subheading')} 🧑‍🚀</GrayText>}
      />
      <SubLayoutContentWrapper>
        <Heading capitalizeAll={true}>{t('masterAdmin.systemAdmins.systemAdmins')}</Heading>
        <div className={'flex flex-col gap-5'}>
          <SearchInput
            placeholder={t('masterAdmin.systemAdmins.systemAdminSearch')}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <ScrollContainer className={'max-w-140 max-h-130'}>
            {filteredSysAdmins.map(sysAdmin => (
              <DimmedListRow key={sysAdmin.userId}>
                <div>
                  <div>{`${sysAdmin.firstName} ${sysAdmin.lastName}`}</div>
                  <div className={'text-gray-200 text-90'}>{sysAdmin.emailAddress}</div>
                </div>
                <div className={'flex items-center gap-5'}>
                  <div>{sysAdmin.currentTenant}</div>
                  <Button
                    type={ButtonType.ICON}
                    clickHandler={() => navigate(sysAdmin.userId)}
                    buttonStyle={ButtonStyle.PRIMARY}
                    value={
                      <CustomIcon
                        iconType={IconType.VECTOR}
                        path={editIcon}
                        alt={t('masterAdmin.systemAdmins.editSystemAdmin')}
                        styles={'bg-gray-50 w-5 h-5'}
                      />
                    }
                  />
                </div>
              </DimmedListRow>
            ))}
          </ScrollContainer>
        </div>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

import { Vendor, Vendors } from '../../../utils/vendors'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useContext } from 'react'
import { TabOptionsWrapper } from './TabSharedComponents'
import { PingType, Tab } from './Tab'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface TabsProps {
  id?: string
  useRoute?: boolean
  showSummaryTab?: boolean
  handleSelection: (vendor: Vendor) => void
  selectedVendor: Vendor
  actionComponent?: React.ReactNode
  showAll?: boolean
  pingVendors?: { vendor: Vendor; type: PingType }[]
}

export const VendorTabs = ({
  id,
  useRoute,
  showSummaryTab,
  handleSelection,
  actionComponent,
  selectedVendor,
  showAll,
  pingVendors
}: TabsProps) => {
  const { authInfo } = useContext(UserInfoContext)
  return (
    <TabOptions>
      <div className={'flex w-full gap-2'}>
        {showSummaryTab && (
          <VendorTab id={id} vendor={Vendor.ALL} handleSelection={handleSelection} selectedVendor={selectedVendor} />
        )}
        {Vendors.filter(vendor => (!showAll && !authInfo.awsAccess ? vendor !== Vendor.AWS : true))
          .filter(vendor => (!showAll && !authInfo.azureAccess ? vendor !== Vendor.AZURE : true))
          .filter(vendor => (!showAll && !authInfo.gcpAccess ? vendor !== Vendor.GCP : true))
          .map(vendor => (
            <VendorTab
              id={id}
              useRoute={useRoute}
              handleSelection={handleSelection}
              key={vendor}
              vendor={vendor}
              selectedVendor={selectedVendor}
              ping={pingVendors?.find(i => i.vendor === vendor)?.type}
            />
          ))}
      </div>
      {actionComponent && <div className={'pr-4'}>{actionComponent}</div>}
    </TabOptions>
  )
}

const TabOptions = styled(TabOptionsWrapper)`
  ${tw`flex justify-between items-center`}
`

interface VendorTabProps {
  vendor: Vendor
  id?: string
  useRoute?: boolean
  handleSelection: (vendor: Vendor) => void
  selectedVendor: Vendor
  ping?: PingType | false
}

const VendorTab = ({ vendor, id, useRoute, handleSelection, selectedVendor, ping }: VendorTabProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const initialPath = location.pathname
    .split('/')
    .filter(path => !Vendors.find(vendor => vendor === path.toUpperCase()))
    .join('/')
  return (
    <Tab
      id={id}
      selectedTab={selectedVendor}
      tabValue={vendor}
      handleSelection={() => {
        handleSelection(vendor)
        useRoute && navigate(`${initialPath}/${vendor.toLowerCase()}`)
      }}
      ping={ping}
    />
  )
}

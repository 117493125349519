import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import { useCancelToken } from './api/client'
import { UserInfoContext } from './state/context/UserInfoContext'
import { GlobalMessage } from './global-message/GlobalMessage'
import { FullScreenLoading } from './components/shared/Loading'
import SpotterRoutes from './layout/routes/SpotterRoutes'
import { getUserContextData } from './api/settings/profile'
import { featuresFlags } from './state/featuresFlags'
import { Notifications } from './components/notifications/Notifications'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const { authInfo, setAuthInfo, setUserSettings, setIntegrationStates } = useContext(UserInfoContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getUserContextData(cancelToken.token)
      .then(resp => {
        setAuthInfo(resp.authInfo)
        setUserSettings(resp.userSettings)
        setIntegrationStates(resp.integrationStates)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [setAuthInfo, createCancelToken, setUserSettings, setIntegrationStates])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <SpotterRoutes />
      {featuresFlags.notifications && authInfo.notificationsAccess && authInfo.isLoggedIn && <Notifications />}
      <GlobalMessage />
    </>
  )
}

export default App

import {
  DetailedIntegrationModal,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationDetailsInfoSection,
  IntegrationDetailsPageHeader,
  IntegrationDetailsWrapper,
  IntegrationsTab,
  IntegrationsTabs,
  IntegrationTimestampsSection,
  LoadingIntegrationDetails
} from '../Shared'
import { AdminContentWrapper, AdminTabContent } from '../../adminStyles'
import React, { useContext, useEffect, useState } from 'react'
import {
  AzureIntegration,
  deleteAzureIntegration,
  editAzureIntegration,
  getAzureIntegrationDetails,
  recoverAzureIntegration
} from '../../../../api/admin/integrations/azure'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useCancelToken } from '../../../../api/client'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { formatDate } from '../../../../utils/formats'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { hasIntegrationErrors } from '../../../../api/settings/profile'
import { Layout } from '../../../../layout/Layout'
import { ModalActions } from '../../../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'

export const AzureIntegrationDetails = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { azureTenantId } = useParams<{
    azureTenantId: string
  }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const [selectedIntegration, setSelectedIntegration] = useState<AzureIntegration | null>(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const handleError = useErrorHandling()

  useEffect(() => {
    if (azureTenantId) {
      setLoading(true)
      getAzureIntegrationDetails(azureTenantId, createCancelToken().token)
        .then(setSelectedIntegration)
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    return () => {
      setSelectedIntegration(null)
      createCancelToken().cancel()
    }
  }, [azureTenantId, createCancelToken, handleError])

  if (loading || !selectedIntegration || !azureTenantId) return <LoadingIntegrationDetails loading={loading} />

  const openDeleteModal = () => {
    const deleteAction = () => {
      deleteAzureIntegration(selectedIntegration.azureTenantId)
        .then(() => {
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.integrations.azure.deleteSuccessToast')
          })
          navigate('/admin/integrations/azure')
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.confirmRemoveRequest'),
      body: (
        <DetailedIntegrationModal
          description={t('admin.integrations.azure.removeDescription')}
          detailRows={[
            { label: t('admin.integrations.azure.tenantId'), value: selectedIntegration.azureTenantId },
            { label: t('admin.integrations.name'), value: selectedIntegration.name }
          ]}
          action={deleteAction}
          actionLabel={t('admin.integrations.remove')}
        />
      )
    })
  }

  const recoverIntegration = () => {
    recoverAzureIntegration(azureTenantId)
      .then(resp => {
        setSelectedIntegration(resp)
        setModal(null)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('admin.integrations.azure.recoverSuccessToast')
        })
      })
      .catch(handleError)
  }

  const editIntegration = (request: AzureIntegration) => {
    editAzureIntegration(request, createCancelToken().token).then(data => {
      setSelectedIntegration(data)
      setMessage({
        type: MessageType.SUCCESS,
        message: t('admin.integrations.azure.editSuccessToast')
      })
    })
  }

  return (
    <Layout type={'sub'}>
      <IntegrationDetailsPageHeader
        name={selectedIntegration.name}
        lastIntegrationAt={selectedIntegration.lastIntegrationAt}
        buttonValue={selectedIntegration.deletedAt && t('admin.integrations.recover')}
        buttonAction={!!selectedIntegration?.deletedAt && recoverIntegration}
        deleteAction={!selectedIntegration?.deletedAt && openDeleteModal}
        backButtonPath={'/admin/integrations/azure'}
      />
      <AdminContentWrapper className={'max-h-[80vh] xl:max-h-[86vh]'}>
        <IntegrationDetails selectedIntegration={selectedIntegration} submitAction={editIntegration} />
      </AdminContentWrapper>
    </Layout>
  )
}

interface IntegrationDetailsProps {
  selectedIntegration: AzureIntegration
  submitAction: (request: AzureIntegration) => void
}

const IntegrationDetails = ({ selectedIntegration, submitAction }: IntegrationDetailsProps) => {
  const { integrationStates } = useContext(UserInfoContext)
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.DETAILS)
  return (
    <div>
      <IntegrationsTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        hasErrors={hasIntegrationErrors(integrationStates.azure)}
      />
      <IntegrationDetailsWrapper>
        <IntegrationDetailsInfoSection removeDate={selectedIntegration.deletedAt} selectedTab={selectedTab} />
        {selectedTab === IntegrationsTab.DETAILS ? (
          <AzureTenantForm selectedIntegration={selectedIntegration} submitAction={submitAction} />
        ) : (
          <IntegrationTimestamps selectedIntegration={selectedIntegration} />
        )}
      </IntegrationDetailsWrapper>
    </div>
  )
}

interface AzureTenantFormProps {
  selectedIntegration: AzureIntegration
  submitAction: (request: AzureIntegration) => void
}

const AzureTenantForm = ({ selectedIntegration, submitAction }: AzureTenantFormProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const [defaultValues, setDefaultValues] = useState<AzureIntegration>({ ...selectedIntegration })

  const EditModalBody = () => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { isDirty, isValid, isSubmitting }
    } = useForm<AzureIntegration>({ defaultValues: defaultValues })

    const onSubmit = (data: AzureIntegration) => {
      submitAction(data)
      setDefaultValues(data)
      reset(data)
    }

    return (
      <>
        <div className={'pb-3'}>
          <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
          <CustomInput
            {...register('name', {
              required: true,
              minLength: 1
            })}
          />
        </div>
        <ModalActions>
          <Button
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => {
              reset(defaultValues)
              setModal(null)
            }}
            value={t('common.cancel')}
          />
          <Button
            type={ButtonType.FORM}
            clickHandler={handleSubmit(onSubmit)}
            value={t('common.saveChanges')}
            disabled={!isDirty || !isValid || isSubmitting}
          />
        </ModalActions>
      </>
    )
  }

  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.azure.azureTenant')}
        editAction={() =>
          setModal({
            header: t('admin.integrations.azure.editAzureTenant'),
            body: <EditModalBody />
          })
        }
      />
      <IntegrationDetailRowsWrapper
        hasActions={false}
        detailRows={
          <>
            <IntegrationDetailRow
              label={t('admin.integrations.azure.tenantId')}
              value={selectedIntegration.azureTenantId}
            />
            <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.name} />
          </>
        }
      />
    </AdminTabContent>
  )
}

interface IntegrationTimestampsProps {
  selectedIntegration: AzureIntegration
}

const IntegrationTimestamps = ({ selectedIntegration }: IntegrationTimestampsProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.azure.azureTenant')} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.lastIntegrations.organization, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastOrganizationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.costs')}
                  value={formatDate(selectedIntegration.lastIntegrations.costs, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastBillingIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.lastIntegrations.optimization, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastRecommendationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.compliance')}
                  value={formatDate(selectedIntegration.lastIntegrations.compliance, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastComplianceIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.infra')}
                  value={formatDate(selectedIntegration.lastIntegrations.infra, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastOrganizationIntegrationAt
                  )}
                />
                <IntegrationDetailRow
                  label={t('sideNav.sustainability')}
                  value={formatDate(selectedIntegration.lastIntegrations.sustainability, true, true, true)}
                  error={integrationStates.azure?.some(
                    i => i.azureTenantId === selectedIntegration.azureTenantId && !i.lastUsageIntegrationAt
                  )}
                />
              </>
            }
          />
        </AdminTabContent>
      }
    />
  )
}

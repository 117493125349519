import { NestedPageHeader } from '../shared/NestedPageHeader'
import { CapitalizedText, GrayText, Heading } from '../shared/TextComponents'
import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  changeSystemAdminCurrentTenant,
  deleteSystemAdminTenantAccess,
  getAllTenantDetails,
  getSystemAdminDetails,
  grantSystemAdminTenantAccess,
  SystemAdmin
} from '../../api/master-admin'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import {
  selectControlStyles,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from '../shared/filters/ReactSelectStyles'
import Select from 'react-select'
import { CheckboxOption } from '../shared/buttons/CheckboxOption'
import { FilterOption, filterSelectAllOption } from '../shared/filters/FilterSelect'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import remove from '../../assets/svg/actions/close.svg'
import { RadioButton } from '../shared/buttons/RadioButton'
import { ModalContext } from '../../state/context/ModalContext'
import { ModalActions, ModalText } from '../shared/modal/Modal'
import { Loading } from '../shared/Loading'
import userIcon from '../../assets/svg/objects/user-2.svg'
import tw from 'twin.macro'
import styled from 'styled-components'
import { ScrollContainer } from '../shared/containers/ScrollContainer'
import { DimmedListRow, LabeledMenu } from './Shared'
import { SearchInput } from '../shared/filters/SearchInput'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { Layout, SubLayoutContentWrapper } from '../../layout/Layout'

export const MasterAdminSystemAdminDetails = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const navigate = useNavigate()
  const location = useLocation()
  const { userId } = useParams<{ userId: string }>()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const [systemAdmin, setSystemAdmin] = useState<SystemAdmin | null>(null)
  const [allTenants, setAllTenants] = useState<FilterOption[]>([])
  const [permittedTenants, setPermittedTenants] = useState<FilterOption[]>([])
  const [filteredPermittedTenants, setFilteredPermittedTenants] = useState<FilterOption[]>([])
  const [filteredPermittedMenuOptions, setFilteredPermittedMenuOptions] = useState<FilterOption[]>([])
  const [filteredChangeMenuOptions, setFilteredChangeMenuOptions] = useState<FilterOption[]>([])
  const [permittedTenantsMenuSearchText, setPermittedTenantsMenuSearchText] = useState('')
  const [permittedTenantsSearchText, setPermittedTenantsSearchText] = useState('')
  const [changeTenantsSearchText, setChangeTenantsSearchText] = useState('')

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (!userId) {
      navigate('/master-admin')
      setMessage({
        message: t('admin.userNotFound_one'),
        type: MessageType.ERROR
      })
    } else {
      setLoading(true)
      Promise.all([
        getAllTenantDetails(cancelToken.token).then(resp =>
          setAllTenants(resp.map(tenant => ({ label: tenant.tenant.name, value: tenant.tenant.id })))
        ),
        getSystemAdminDetails(userId, cancelToken.token).then(resp => {
          setSystemAdmin(resp)
          setPermittedTenants(resp.permittedTenants.map(tenant => ({ label: tenant.name, value: tenant.id })))
        })
      ])
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setSystemAdmin(null)
    }
  }, [createCancelToken, handleError, navigate, setMessage, t, userId])

  useEffect(() => {
    if (permittedTenantsMenuSearchText !== '')
      setFilteredPermittedMenuOptions(
        allTenants.filter(
          option =>
            option.label.toLowerCase().includes(permittedTenantsMenuSearchText.toLowerCase()) ||
            option.value.toLowerCase().includes(permittedTenantsMenuSearchText.toLowerCase())
        )
      )
    else setFilteredPermittedMenuOptions(allTenants)

    if (permittedTenantsSearchText !== '')
      setFilteredPermittedTenants(
        permittedTenants.filter(
          option =>
            option.label.toLowerCase().includes(permittedTenantsSearchText.toLowerCase()) ||
            option.value.toLowerCase().includes(permittedTenantsSearchText.toLowerCase())
        )
      )
    else setFilteredPermittedTenants(permittedTenants)

    if (changeTenantsSearchText !== '')
      setFilteredChangeMenuOptions(
        allTenants.filter(
          option =>
            option.label.toLowerCase().includes(changeTenantsSearchText.toLowerCase()) ||
            option.value.toLowerCase().includes(changeTenantsSearchText.toLowerCase())
        )
      )
    else setFilteredChangeMenuOptions(allTenants)
  }, [
    allTenants,
    permittedTenants,
    permittedTenantsSearchText,
    permittedTenantsMenuSearchText,
    changeTenantsSearchText
  ])

  if (!systemAdmin) return null

  const handlePermissionSelect = (selectedTenant: FilterOption) => {
    const cancelToken = createCancelToken()
    const selectedIsPermitted =
      permittedTenants.some(tenant => JSON.stringify(tenant) === JSON.stringify(selectedTenant)) ||
      (selectedTenant.value === filterSelectAllOption.value &&
        filteredPermittedMenuOptions.every(o => permittedTenants.some(tenant => tenant.value === o.value)))

    const grantPermission = (tenantIds?: string[]) => {
      grantSystemAdminTenantAccess(systemAdmin.userId, selectedTenant.value, cancelToken.token, tenantIds)
        .then(resp => {
          setSystemAdmin(resp)
          setPermittedTenants(resp.permittedTenants.map(tenant => ({ label: tenant.name, value: tenant.id })))
          setMessage({
            message:
              selectedTenant.value === filterSelectAllOption.value
                ? t('masterAdmin.systemAdmins.allTenantAccessGranted')
                : t('masterAdmin.systemAdmins.tenantAccessGranted', { name: selectedTenant.label }),
            type: MessageType.SUCCESS
          })
        })
        .catch(handleError)
    }
    const deletePermission = (tenantIds?: string[]) => {
      deleteSystemAdminTenantAccess(systemAdmin.userId, selectedTenant.value, cancelToken.token, tenantIds)
        .then(resp => {
          setSystemAdmin(resp)
          setPermittedTenants(resp.permittedTenants.map(tenant => ({ label: tenant.name, value: tenant.id })))
          setMessage({
            message:
              selectedTenant.value === filterSelectAllOption.value
                ? t('masterAdmin.systemAdmins.allTenantAccessDeleted')
                : t('masterAdmin.systemAdmins.tenantAccessDeleted', { name: selectedTenant.label }),
            type: MessageType.SUCCESS
          })
        })
        .catch(handleError)
    }

    selectedIsPermitted
      ? selectedTenant.value === filterSelectAllOption.value
        ? deletePermission(filteredPermittedMenuOptions.map(option => option.value))
        : deletePermission()
      : selectedTenant.value === filterSelectAllOption.value
        ? grantPermission(filteredPermittedMenuOptions.map(option => option.value))
        : grantPermission()
  }

  const handleTenantChange = (selectedTenant: FilterOption) => {
    const cancelToken = createCancelToken()
    const selectedIsPermitted = permittedTenants.some(
      tenant => JSON.stringify(tenant) === JSON.stringify(selectedTenant)
    )
    const changeTenant = () =>
      userId &&
      changeSystemAdminCurrentTenant(userId, selectedTenant.value, cancelToken.token)
        .then(resp => {
          setSystemAdmin(resp)
          setPermittedTenants(resp.permittedTenants.map(tenant => ({ label: tenant.name, value: tenant.id })))
          setMessage({
            message: t('masterAdmin.tenantChanged', { name: selectedTenant.label }),
            type: MessageType.SUCCESS
          })
          setModal(null)
        })
        .catch(handleError)

    selectedIsPermitted
      ? changeTenant()
      : setModal({
          header: t('common.notificationHeading.WARNING'),
          body: <TenantChangeModal selectedTenant={selectedTenant} changeTenant={changeTenant} />
        })
  }

  if (loading)
    return (
      <Layout type={'sub'}>
        <NestedPageHeader
          mainHeading={`${systemAdmin.firstName} ${systemAdmin.lastName}`}
          subHeading={<GrayText>{systemAdmin.currentTenant.name}</GrayText>}
          navigateBack={true}
        />
        <SubLayoutContentWrapper>
          <div className={'flex flex-col gap-6 w-fit'}>
            <Heading>{t('masterAdmin.systemAdmins.permittedTenants')}</Heading>
            <Loading paddingY={'1rem'} />
          </div>
        </SubLayoutContentWrapper>
      </Layout>
    )

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={`${systemAdmin.firstName} ${systemAdmin.lastName}`}
        subHeading={<GrayText>{systemAdmin.currentTenant.name}</GrayText>}
        navigateBack={true}
        actions={
          <Button
            value={
              <CustomIcon
                path={userIcon}
                iconType={IconType.VECTOR}
                styles={'w-6 h-6 bg-gray-50'}
                alt={t('masterAdmin.userAccessDetails')}
              />
            }
            type={ButtonType.ICON}
            size={ButtonSize.SMALL}
            clickHandler={() =>
              navigate(location.pathname.replace(`/system-admins`, '/users'), { state: { from: 'system-admins' } })
            }
          />
        }
      />
      <SubLayoutContentWrapper>
        <ContentWrapper>
          <Heading>{t('masterAdmin.systemAdmins.permittedTenants')}</Heading>
          <div className={'flex flex-col gap-12'}>
            <div className={'flex flex-col gap-5'}>
              <div className={'flex gap-5 flex-col lg:gap-12 lg:flex-row'}>
                <PermittedTenantsMenu
                  systemAdmin={systemAdmin}
                  options={filteredPermittedMenuOptions}
                  selectedTenants={permittedTenants}
                  searchText={permittedTenantsMenuSearchText}
                  setSearchText={setPermittedTenantsMenuSearchText}
                  handleSelect={handlePermissionSelect}
                />
                {permittedTenants.length > 0 && permittedTenants.length < allTenants.length && (
                  <LabeledMenu
                    label={t('masterAdmin.systemAdmins.searchTenant')}
                    menu={
                      <SearchInput
                        placeholder={t('masterAdmin.systemAdmins.searchTenantPlaceholder')}
                        searchText={permittedTenantsSearchText}
                        setSearchText={setPermittedTenantsSearchText}
                      />
                    }
                  />
                )}
              </div>
              <PermittedTenantsList
                systemAdmin={systemAdmin}
                allTenants={allTenants}
                permittedTenants={filteredPermittedTenants}
                handlePermissionSelect={handlePermissionSelect}
              />
            </div>
            <ChangeTenant
              systemAdmin={systemAdmin}
              options={filteredChangeMenuOptions}
              searchText={changeTenantsSearchText}
              setSearchText={setChangeTenantsSearchText}
              handleTenantChange={handleTenantChange}
            />
          </div>
        </ContentWrapper>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

const ContentWrapper = styled.div`
  ${tw`flex flex-col gap-6 w-fit`}
`

interface PermittedTenantsListProps {
  systemAdmin: SystemAdmin
  allTenants: FilterOption[]
  permittedTenants: FilterOption[]
  handlePermissionSelect: (selectedTenant: FilterOption) => void
}

const PermittedTenantsList = ({
  systemAdmin,
  allTenants,
  permittedTenants,
  handlePermissionSelect
}: PermittedTenantsListProps) => {
  const { t } = useTranslation()

  return (
    <>
      {systemAdmin.permittedTenants.length === 0 ? (
        <CapitalizedText>{t('masterAdmin.systemAdmins.accessToZeroTenants')}</CapitalizedText>
      ) : systemAdmin.permittedTenants.length === allTenants.length ? (
        <CapitalizedText>{t('masterAdmin.systemAdmins.accessToAllTenants')}</CapitalizedText>
      ) : permittedTenants.length === 0 ? (
        <MissingDataNotification displayText={t('masterAdmin.systemAdmins.noTenantsFound')} />
      ) : (
        <ScrollContainer className={'w-fit px-2 max-w-140 max-h-130'}>
          {permittedTenants.map(tenant => (
            <DimmedListRow key={tenant.value} className={'gap-4'}>
              <div>
                <div>{tenant.label}</div>
                <div className={'text-gray-200 text-90'}>{tenant.value}</div>
              </div>
              <div>
                {tenant.value === systemAdmin.currentTenant.id ? (
                  <GrayText className={'text-90 w-max'}>({t('common.current')})</GrayText>
                ) : (
                  <Button
                    value={
                      <CustomIcon
                        styles={'w-5 h-5 bg-gray-200 hover:bg-gray-50'}
                        iconType={IconType.VECTOR}
                        path={remove}
                      />
                    }
                    type={ButtonType.ICON}
                    size={ButtonSize.XSMALL}
                    buttonStyle={ButtonStyle.GHOST}
                    clickHandler={() => handlePermissionSelect({ label: tenant.label, value: tenant.value })}
                  />
                )}
              </div>
            </DimmedListRow>
          ))}
        </ScrollContainer>
      )}
    </>
  )
}

interface PermittedTenantsMenuProps {
  systemAdmin: SystemAdmin
  options: FilterOption[]
  selectedTenants: FilterOption[]
  searchText: string
  setSearchText: (text: string) => void
  handleSelect: (selectedTenant: FilterOption) => void
}

const PermittedTenantsMenu = ({
  systemAdmin,
  options,
  selectedTenants,
  searchText,
  setSearchText,
  handleSelect
}: PermittedTenantsMenuProps) => {
  const { t } = useTranslation()
  return (
    <LabeledMenu
      label={t('masterAdmin.systemAdmins.selectTenants')}
      menu={
        <MasterAdminTenantMenu
          type={'multi'}
          currentTenantId={systemAdmin.currentTenant.id}
          options={options}
          selectedTenants={selectedTenants}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSelect={handleSelect}
        />
      }
    />
  )
}

interface ChangeTenantProps {
  systemAdmin: SystemAdmin
  options: FilterOption[]
  searchText: string
  setSearchText: (text: string) => void
  handleTenantChange: (selectedTenant: FilterOption) => void
}

const ChangeTenant = ({ systemAdmin, options, searchText, setSearchText, handleTenantChange }: ChangeTenantProps) => {
  const { t } = useTranslation()
  return (
    <LabeledMenu
      label={t('masterAdmin.systemAdmins.selectNewTenant')}
      menu={
        <MasterAdminTenantMenu
          type={'single'}
          currentTenantId={systemAdmin.currentTenant.id}
          menuPlaceholder={systemAdmin.currentTenant.name}
          options={options}
          selectedTenants={[{ label: systemAdmin.currentTenant.name, value: systemAdmin.currentTenant.id }]}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSelect={handleTenantChange}
        />
      }
    />
  )
}

interface TenantMenuProps {
  type: 'single' | 'multi'
  currentTenantId: string
  menuPlaceholder?: string
  options: FilterOption[]
  selectedTenants: FilterOption[]
  searchText: string
  setSearchText: (text: string) => void
  handleSelect: (selectedTenant: FilterOption) => void
}

export const MasterAdminTenantMenu = ({
  type,
  currentTenantId,
  menuPlaceholder,
  selectedTenants,
  options,
  searchText,
  setSearchText,
  handleSelect
}: TenantMenuProps) => {
  const { t } = useTranslation()
  const allOptions = [filterSelectAllOption, ...options]

  const formatOptionLabel = (option: FilterOption) => {
    const isCurrentTenant = option.value === currentTenantId
    const checked =
      selectedTenants.some(selected => option.value === selected.value) ||
      (options.every(option => selectedTenants.some(selected => option.value === selected.value)) &&
        option.value === filterSelectAllOption.value)

    if (type === 'multi')
      return (
        <CheckboxOption
          label={option.label}
          value={isCurrentTenant ? t('masterAdmin.currentTenant') : undefined}
          type={'filter'}
          clickHandler={() => undefined}
          checked={checked || isCurrentTenant}
          disabled={isCurrentTenant}
        />
      )
    return (
      <RadioButton
        checked={option.value === currentTenantId}
        onChange={() => handleSelect(option)}
        label={option.label}
        fullWidth={true}
      />
    )
  }

  const placeholder =
    menuPlaceholder ??
    (selectedTenants.length === options.length
      ? t('filters.allSelected', { count: selectedTenants.length })
      : selectedTenants.length > 0
        ? t('filters.multipleSelected', { count: selectedTenants.length })
        : t('common.select'))

  const filterOption = (option: FilterOption) => {
    return options.length > 0 || options.some(filtered => JSON.stringify(filtered) === JSON.stringify(option))
  }

  return (
    <Select
      unstyled
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      filterOption={filterOption}
      placeholder={placeholder}
      formatOptionLabel={formatOptionLabel}
      inputValue={searchText}
      onInputChange={value => setSearchText(value)}
      styles={{ menu: () => ({ position: 'static' }) }}
      classNames={{
        control: () => selectControlStyles + ' w-75',
        menuList: () => selectMenuListStyles + ' min-h-85 max-h-96 min-w-75 w-max max-w-125',
        noOptionsMessage: () => selectNoOptionsStyles,
        placeholder: () => 'text-gray-200',
        option: state => selectOptionStyles + ` max-w-full ${state.isFocused && 'bg-gray-500/30 text-gray-50'}`
      }}
      options={type === 'multi' ? allOptions : options}
      isOptionDisabled={option => option.value === currentTenantId}
      onChange={selected => {
        handleSelect(selected as FilterOption)
        setSearchText(searchText)
      }}
      components={{
        DropdownIndicator: SelectDropdownIndicator,
        IndicatorSeparator: SelectIndicatorSeparator
      }}
    />
  )
}

interface TenantChangeModalProps {
  selectedTenant: FilterOption
  changeTenant: () => void
}

const TenantChangeModal = ({ selectedTenant, changeTenant }: TenantChangeModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  return (
    <>
      <div className={'flex flex-col gap-4'}>
        <ModalText>
          <Trans>
            {t('masterAdmin.systemAdmins.changeTenantModal.accessGrantWarning', { tenantName: selectedTenant.label })}
          </Trans>
        </ModalText>
        <ModalText>{t('masterAdmin.systemAdmins.changeTenantModal.proceedQuestion')}</ModalText>
      </div>
      <ModalActions>
        <Button value={t('common.no')} clickHandler={() => setModal(null)} />
        <Button value={t('common.yes')} clickHandler={changeTenant} />
      </ModalActions>
    </>
  )
}

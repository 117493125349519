import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { getUserDetails, OrganizationUser } from '../../../api/admin/users'
import { Loading } from '../../shared/Loading'
import { formatDate } from '../../../utils/formats'
import { TabOptionsWrapper } from '../../shared/tabs/TabSharedComponents'
import { Tab } from '../../shared/tabs/Tab'
import { useNavigate, useParams } from 'react-router-dom'
import { EditAccessRights } from './tabs/EditAccessRights'
import { EditVisibility } from './tabs/EditVisibility'
import { GrayText } from '../../shared/TextComponents'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { NestedPageHeader } from '../../shared/NestedPageHeader'
import { AdminContentWrapper } from '../adminStyles'
import { Layout, SubLayoutContentWrapper } from '../../../layout/Layout'

export enum UserEditTab {
  ACCESS_RIGHTS,
  VISIBILITY
}

export const UserDetails = () => {
  const { authInfo } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { userId } = useParams<{ userId: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<OrganizationUser | null>(null)
  const [selectedTab, setSelectedTab] = useState<UserEditTab>(UserEditTab.ACCESS_RIGHTS)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (userId) {
      setLoading(true)
      getUserDetails(userId, cancelToken.token)
        .then(setUser)
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [createCancelToken, handleError, userId])

  useEffect(() => {
    user && user.tenantId !== authInfo.tenant.id && navigate('/admin/users')
  }, [authInfo, navigate, user])

  if (!user || loading)
    return (
      <Layout type={'sub'}>
        <SubLayoutContentWrapper>
          {loading ? (
            <Loading paddingY={'1rem'} />
          ) : (
            <GrayText className={'m-auto underline cursor-pointer'} onClick={() => navigate('/admin/users')}>
              {t('common.return')}
            </GrayText>
          )}
        </SubLayoutContentWrapper>
      </Layout>
    )

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={`${user.firstName} ${user.lastName}`}
        capitalize={false}
        subHeading={<GrayText>{`${t('admin.updated')} ${formatDate(user.updatedAt, true, true)}`}</GrayText>}
        backButtonPath={'/admin/users'}
      />
      <AdminContentWrapper>
        <UserDetailsTabOptions selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <div>
          {selectedTab === UserEditTab.ACCESS_RIGHTS ? (
            <EditAccessRights user={user} setUser={setUser} />
          ) : (
            <EditVisibility user={user} setUser={setUser} />
          )}
        </div>
      </AdminContentWrapper>
    </Layout>
  )
}

interface TabOptionsProps {
  selectedTab: UserEditTab
  setSelectedTab: (tabOption: UserEditTab) => void
}

export const UserDetailsTabOptions = ({ selectedTab, setSelectedTab }: TabOptionsProps) => {
  const { t } = useTranslation()
  return (
    <TabOptionsWrapper>
      <Tab
        styles={'lg:max-w-60'}
        tabLabel={t('admin.tabs.rolesAndAccessRights.tabLabel')}
        tabValue={UserEditTab.ACCESS_RIGHTS}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(UserEditTab.ACCESS_RIGHTS)}
      />
      <Tab
        tabLabel={t('admin.tabs.visibility.tabLabel')}
        tabValue={UserEditTab.VISIBILITY}
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(UserEditTab.VISIBILITY)}
      />
    </TabOptionsWrapper>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CapitalizedText } from '../shared/TextComponents'
import {
  ChangeType,
  CostAnomalyNotification,
  getCostAnomalyNotifications,
  INCREASING_COST_ANOMALY_STATES
} from '../../api/notifications'
import { useCancelToken } from '../../api/client'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { ModalContext } from '../../state/context/ModalContext'
import { useErrorHandling } from '../../hooks/handleError'
import { ModalActions, ModalText } from '../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import styled from 'styled-components'
import tw from 'twin.macro'
import { formatNumber } from '../../utils/formats'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { useNavigate } from 'react-router-dom'
import settings from '../../assets/svg/objects/cog.svg'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import { getCurrency } from '../../utils/Currency'
import {
  changeCostAnomalyProjectLimits,
  changeCostAnomalyServiceLimits,
  CostAnomalyLimitData
} from '../../api/settings/notifications'
import { setNotificationProject, setNotificationService } from '../../state/storage'
import { CostAnomalyLimitInputs } from '../shared/CostAnomalyLimitInputs'
import { CostAnomalyLimits } from '../../api/admin/alerts'

interface CostAnomalySettingsModalProps {
  notification: CostAnomalyNotification
}

export const CostNotificationModalHeader = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const navigate = useNavigate()

  return (
    <div className={'flex gap-5 items-center text-center'}>
      <CustomIcon
        styles={'w-5 h-5 bg-gray-200 hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={settings}
        onClick={() => {
          setModal(null)
          setNotificationsOpen(false)
          navigate(`/settings/notifications/${notification.vendor.toLowerCase()}`)
        }}
        tooltipText={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewAllSettings')}
        tooltipStyles={'mt-12 w-max ml-5'}
      />
      <CapitalizedText className={'w-full'}>
        {t('notifications.costAnomalies.costAnomaly.menuOptions.changeLimits')}
      </CapitalizedText>
    </div>
  )
}

export const CostNotificationModalBody = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const navigate = useNavigate()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const { setNotifications } = useContext(NotificationsContext)
  const handleError = useErrorHandling()
  const [projectLimits, setProjectLimits] = useState<CostAnomalyLimitData>(notification.projectLimits)
  const [serviceLimits, setServiceLimits] = useState<CostAnomalyLimitData>(notification.serviceLimits)
  const [currentLimits, setCurrentLimits] = useState<CostAnomalyLimitData>(notification.anomalyLimits)
  const [loading, setLoading] = useState(false)
  const isIncreasing = INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)

  useEffect(() => {
    const increasePercent =
      projectLimits.increasePercent > serviceLimits.increasePercent
        ? projectLimits.increasePercent
        : serviceLimits.increasePercent
    const decreasePercent =
      projectLimits.decreasePercent < serviceLimits.decreasePercent
        ? projectLimits.decreasePercent
        : serviceLimits.decreasePercent
    const increaseMonthlyImpact =
      projectLimits.increaseMonthlyImpact > serviceLimits.increaseMonthlyImpact
        ? projectLimits.increaseMonthlyImpact
        : serviceLimits.increaseMonthlyImpact
    const decreaseMonthlyImpact =
      projectLimits.decreaseMonthlyImpact < serviceLimits.decreaseMonthlyImpact
        ? projectLimits.decreaseMonthlyImpact
        : serviceLimits.decreaseMonthlyImpact

    setCurrentLimits({
      decreasePercent: decreasePercent,
      increasePercent: increasePercent,
      increaseMonthlyImpact: increaseMonthlyImpact,
      decreaseMonthlyImpact: decreaseMonthlyImpact,
      monthlyBudget: projectLimits.monthlyBudget,
      budgetAlertPercent: projectLimits.budgetAlertPercent,
      currency: notification.currency,
      isDefault: projectLimits.isDefault && serviceLimits.isDefault
    })
  }, [projectLimits, serviceLimits, isIncreasing])

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (JSON.stringify(projectLimits) !== JSON.stringify(notification.projectLimits)) {
      setLoading(true)
      changeCostAnomalyProjectLimits(
        notification.vendor,
        [
          {
            vendor: notification.vendor,
            project: notification.project,
            limits: projectLimits,
            costNotificationsDisabledAt: null,
            isSubscribedToEmail: !!notification.emailSubscriptions?.project
          }
        ],
        cancelToken.token
      )
        .then(() => {
          getCostAnomalyNotifications(cancelToken.token)
            .then(resp => {
              setNotifications(resp)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    if (JSON.stringify(serviceLimits) !== JSON.stringify(notification.serviceLimits)) {
      setLoading(true)
      changeCostAnomalyServiceLimits(
        notification.vendor,
        [
          {
            vendor: notification.vendor,
            service: notification.service,
            limits: serviceLimits,
            isSubscribedToEmail: !!notification.emailSubscriptions?.service
          }
        ],
        cancelToken.token
      )
        .then(() => {
          getCostAnomalyNotifications(cancelToken.token)
            .then(resp => {
              setNotifications(resp)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }
  }, [createCancelToken, handleError, projectLimits, serviceLimits])

  return (
    <>
      <div className={'flex flex-col items-center divide-y divide-gray-500/60 max-w-120'}>
        <ModalInputsBlock
          label={notification.project.name}
          limits={projectLimits}
          setLimits={setProjectLimits}
          type={isIncreasing ? ChangeType.INCREASING : ChangeType.DECREASING}
        />
        <ModalInputsBlock
          label={notification.service}
          limits={serviceLimits}
          setLimits={setServiceLimits}
          type={isIncreasing ? ChangeType.INCREASING : ChangeType.DECREASING}
        />
      </div>
      <TextWrapper className={'max-w-116 text-80'}>
        <Trans>
          {t('notifications.costAnomalies.costAnomaly.limitChangeModal.limitsDescription', {
            changeLabel: isIncreasing
              ? t('notifications.costAnomalies.increase')
              : t('notifications.costAnomalies.decrease'),
            percent: isNaN(isIncreasing ? currentLimits.increasePercent : currentLimits.decreasePercent)
              ? 1
              : isIncreasing
                ? currentLimits.increasePercent
                : currentLimits.decreasePercent,
            impact: isNaN(isIncreasing ? currentLimits.increaseMonthlyImpact : currentLimits.decreaseMonthlyImpact)
              ? 1
              : formatNumber(isIncreasing ? currentLimits.increaseMonthlyImpact : currentLimits.decreaseMonthlyImpact),
            currency: getCurrency(notification.currency).symbol
          })}
        </Trans>
      </TextWrapper>
      <ModalActions>
        <Button
          value={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewSettings')}
          clickHandler={() => {
            setNotificationProject(notification.project.id)
            setNotificationService(notification.service)
            setNotificationsOpen(false)
            setModal(null)
            navigate(`/settings/notifications/${notification.vendor.toLowerCase()}`)
          }}
          buttonStyle={ButtonStyle.SECONDARY}
          type={ButtonType.FORM}
          disabled={loading}
        />
      </ModalActions>
    </>
  )
}

interface ModalInputsBlockProps {
  label: string
  limits: CostAnomalyLimitData
  setLimits: (limits: CostAnomalyLimitData) => void
  type: ChangeType
}

const ModalInputsBlock = ({ label, limits, setLimits, type }: ModalInputsBlockProps) => {
  return (
    <div className={'flex flex-col gap-4 w-full px-5 py-9 first:pt-5'}>
      <div className={'text-center text-gray-50 font-semibold'}>{label}</div>
      <div className={'flex flex-col gap-4 w-full items-center'}>
        {type === ChangeType.DECREASING ? (
          <CostAnomalyLimitInputs
            type={ChangeType.DECREASING}
            limits={{ percent: limits.decreasePercent, monthlyImpact: limits.decreaseMonthlyImpact }}
            currency={limits.currency}
            onBlur={(value?: CostAnomalyLimits) => {
              value &&
                (value.percent !== limits.decreasePercent || value.monthlyImpact !== limits.decreaseMonthlyImpact) &&
                setLimits({
                  ...limits,
                  decreasePercent: value.percent,
                  decreaseMonthlyImpact: value.monthlyImpact
                })
            }}
          />
        ) : (
          <CostAnomalyLimitInputs
            type={ChangeType.INCREASING}
            limits={{ percent: limits.increasePercent, monthlyImpact: limits.increaseMonthlyImpact }}
            currency={limits.currency}
            onBlur={(value?: CostAnomalyLimits) => {
              value &&
                (value.percent !== limits.increasePercent || value.monthlyImpact !== limits.increaseMonthlyImpact) &&
                setLimits({
                  ...limits,
                  increasePercent: value.percent,
                  increaseMonthlyImpact: value.monthlyImpact
                })
            }}
          />
        )}
      </div>
    </div>
  )
}

const InputWrapper = styled.div`
  ${tw`flex w-full justify-between items-center gap-5`}
  div:nth-child(2) {
    ${tw`w-2/5`}
  }
`

const TextWrapper = styled(ModalText)`
  strong {
    ${tw`text-gray-50`}
  }
`

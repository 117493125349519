import { GrayText, WhiteText } from './TextComponents'
import { LimitInput } from './NotificationLimitsInput'
import { ChangeType } from '../../api/notifications'
import { CurrencyId, getCurrency } from '../../utils/Currency'
import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { CostAnomalyLimits } from '../../api/admin/alerts'
import { UserInfoContext } from '../../state/context/UserInfoContext'

interface LimitInputsProps {
  type: ChangeType
  limits: CostAnomalyLimits
  onChange?: (limits?: CostAnomalyLimits) => void
  onBlur: (limits?: CostAnomalyLimits) => void
  currency?: CurrencyId
}

export const CostAnomalyLimitInputs = ({ type, limits, onChange, onBlur, currency }: LimitInputsProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)

  return (
    <div className={'sm:min-w-80'}>
      <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
        {type === ChangeType.DECREASING
          ? t('notifications.costAnomalies.decrease')
          : t('notifications.costAnomalies.increase')}
      </WhiteText>
      <Row>
        <GrayText>{t('settings.notifications.changePercent')}</GrayText>
        <LimitInput
          value={limits.percent}
          onChange={value => onChange && onChange({ ...limits, percent: value ?? limits.percent })}
          onBlur={(value?: number) => onBlur({ ...limits, percent: value ?? limits.percent })}
          min={type === ChangeType.DECREASING ? -100 : 1}
          max={type === ChangeType.DECREASING ? -1 : 100}
          type={type}
        />
      </Row>
      <Row>
        <GrayText>
          {t('settings.notifications.monthlyImpact', {
            currency: getCurrency(currency || userSettings.currency).symbol
          })}
        </GrayText>
        <LimitInput
          value={limits.monthlyImpact}
          onChange={value => onChange && onChange({ ...limits, monthlyImpact: value ?? limits.monthlyImpact })}
          onBlur={(value?: number) => onBlur({ ...limits, monthlyImpact: value ?? limits.monthlyImpact })}
          min={type === ChangeType.DECREASING ? -99999 : 1}
          max={type === ChangeType.DECREASING ? -1 : 99999}
          type={type}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  ${tw`flex w-full justify-between items-center gap-5 py-1 text-90 first:pt-0 last:pb-0`}
`

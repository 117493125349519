import { useTranslation } from 'react-i18next'
import { CostAnomalyProject, TenantCostAnomalyRule } from '../../../api/admin/alerts'
import { Button, ButtonStyle, ButtonType, DeleteButton } from '../../shared/buttons/Button'
import { CustomIcon } from '../../shared/CustomIcon'
import editIcon from '../../../assets/svg/actions/edit.svg'
import { formatDate } from '../../../utils/formats'
import { GrayText } from '../../shared/TextComponents'
import { Vendor } from '../../../utils/vendors'
import { ScrollTable, ScrollTableRowItem } from '../../shared/containers/ScrollTable'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface CostAnomalyRuleListProps {
  rules: TenantCostAnomalyRule[]
  deleteRule: (rule: TenantCostAnomalyRule) => void
}

export const TenantCostAnomalyRuleList = ({ rules, deleteRule }: CostAnomalyRuleListProps) => {
  const { t } = useTranslation()

  const titles = [
    t('common.name'),
    t('admin.alerts.costs.recipients'),
    t('admin.alerts.costs.targets'),
    t('admin.alerts.costs.updatedAt')
  ]

  return (
    <ScrollTable
      customColumns={'repeat(4, 1fr) minmax(100px, auto)'}
      sortable={false}
      titles={titles}
      rows={
        rules.length > 0
          ? rules.map(rule => <RuleRow key={rule.ruleId} rule={rule} deleteRule={deleteRule} titles={titles} />)
          : [
              <MissingDataNotification
                paddingY={4}
                justify={'start'}
                displayText={t('admin.alerts.costs.ruleNotFound')}
              />
            ]
      }
    />
  )
}

interface RuleRowProps {
  rule: TenantCostAnomalyRule
  deleteRule: (rule: TenantCostAnomalyRule) => void
  titles: string[]
}

const RuleRow = ({ titles, rule, deleteRule }: RuleRowProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const getTargetCount = (targets: CostAnomalyProject[], vendor: Vendor) =>
    targets.filter(t => t.vendor === vendor).length

  return (
    <>
      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true}>{titles[0]}</GrayText>
        {rule.name || '-'}
      </ScrollTableRowItem>
      {rule.emailAddresses.length > 3 ? (
        <ScrollTableRowItem>
          <GrayText hideOnWideScreen={true}>{titles[1]}</GrayText>
          {t('admin.alerts.costs.emailAddress', { count: rule.emailAddresses.length })}
        </ScrollTableRowItem>
      ) : (
        <ScrollTableRowItem>
          <GrayText hideOnWideScreen={true}>{titles[1]}</GrayText>
          <div className={'text-right lg:text-left'}>
            {rule.emailAddresses.map(email => (
              <div key={email}>{email}</div>
            ))}
          </div>
        </ScrollTableRowItem>
      )}
      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true}>{titles[2]}</GrayText>
        <div className={'text-right lg:text-left'}>
          {getTargetCount(rule.targets, Vendor.AWS) > 0 && (
            <div>
              {`${getTargetCount(rule.targets, Vendor.AWS)} ${t('vendors.AWS.short')} ${t('vendors.AWS.projectPhrase', { count: getTargetCount(rule.targets, Vendor.AWS) })}`}
            </div>
          )}
          {getTargetCount(rule.targets, Vendor.AZURE) > 0 && (
            <div>
              {`${getTargetCount(rule.targets, Vendor.AZURE)} ${t('vendors.AZURE.short')} ${t('vendors.AZURE.projectPhrase', { count: getTargetCount(rule.targets, Vendor.AZURE) })}`}
            </div>
          )}
          <div>
            {getTargetCount(rule.targets, Vendor.GCP) > 0 &&
              `${getTargetCount(rule.targets, Vendor.GCP)} ${t('vendors.GCP.short')} ${t('vendors.GCP.projectPhrase', { count: getTargetCount(rule.targets, Vendor.GCP) })}`}
          </div>
        </div>
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <GrayText hideOnWideScreen={true}>{titles[3]}</GrayText>
        {formatDate(rule.updatedAt)}
      </ScrollTableRowItem>
      <ScrollTableRowItem>
        <div className={'flex gap-5 items-center mx-auto'}>
          <Button
            type={ButtonType.ICON}
            buttonStyle={ButtonStyle.GHOST}
            clickHandler={() => navigate(`${location.pathname}/${rule.ruleId}`)}
            value={<CustomIcon path={editIcon} styles={'bg-gray-100/90 w-5 h-5'} />}
            tooltipText={t('common.edit')}
          />
          <DeleteButton size={5} clickHandler={() => deleteRule(rule)} tooltipText={t('common.delete')} />
        </div>
      </ScrollTableRowItem>
    </>
  )
}

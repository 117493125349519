import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useNavigate } from 'react-router-dom'
import { GradientText, GrayText } from '../shared/TextComponents'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'

interface SummaryContainerProps {
  isClickable?: boolean
  title: string
  children: React.ReactNode
}

export const HomeSummaryContainer = ({ isClickable = true, children, title }: SummaryContainerProps) => {
  const navigate = useNavigate()

  return (
    <Container className={'group'} onClick={() => isClickable && navigate('/' + title)} isClickable={isClickable}>
      <ContentWrapper>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </ContentWrapper>
    </Container>
  )
}

interface NoDataSummaryContainerProps {
  title: string
  isClickable?: boolean
  loading: boolean
}

export const HomeNoDataSummaryContainer = ({ title, isClickable = true, loading }: NoDataSummaryContainerProps) => {
  const navigate = useNavigate()

  return (
    <Container onClick={() => isClickable && navigate('/' + title)} isClickable={isClickable}>
      <ContentWrapper>
        <Title>{title}</Title>
        <Content>{loading ? <Loading /> : <MissingDataNotification justify={'center'} />}</Content>
      </ContentWrapper>
    </Container>
  )
}

interface ContainerProps {
  isClickable: boolean
}

const Container = styled.div<ContainerProps>`
  ${tw`rounded-md bg-gray-600 p-4 lg:p-9 shadow-container`}
  ${({ isClickable }) => isClickable && tw`hover:cursor-pointer`}
`

const ContentWrapper = styled.div`
  ${tw`relative h-full bg-opacity-100 rounded-md`}
`

const Title = styled.h2`
  ${tw`group-hover:text-gray-50 transition-colors ease duration-200 absolute left-2 uppercase text-gray-200 tracking-wider -mt-6 lg:-mt-11`}
`

const Content = styled.div`
  ${tw`flex flex-row gap-5 justify-center items-center w-full h-full md:gap-10`}
`

interface AmountProps {
  color?: 'gradient-primary' | 'gradient-green' | 'gradient-gray'
  textSize?: 'small' | 'medium' | 'large' | 'larger' | 'extra-large'
  fontWeight?: 'semibold' | 'bold' | 'black'
}

export const SummaryAmount = styled(GradientText)<AmountProps>`
  ${tw`transition-all ease-in-out`}
  ${({ fontWeight }) =>
    fontWeight === 'black' ? tw`font-black` : fontWeight === 'bold' ? tw`font-bold` : tw`font-semibold`}
  ${({ color }) =>
    color === 'gradient-primary'
      ? tw`from-primary-500 to-primary-600`
      : color === 'gradient-green'
        ? tw`from-success-100 to-success-500`
        : tw`from-gray-50 to-gray-100`}
  ${({ textSize }) =>
    textSize === 'small'
      ? tw`text-112`
      : textSize === 'medium'
        ? tw`text-125`
        : textSize === 'large'
          ? tw`text-200`
          : textSize === 'larger'
            ? tw`text-250 md:text-350`
            : textSize === 'extra-large'
              ? tw`text-300 md:text-450`
              : tw`text-250 md:text-300`}
`

interface SummaryTextProps {
  lowercase?: boolean
  largerText?: boolean
  strongColor?: 'primary' | 'gray'
}

export const SummaryText = styled(GrayText)<SummaryTextProps>`
  ${tw`text-90 md:text-100`}
  ${({ largerText }) => largerText && tw`lg:text-112`}
  ${({ lowercase }) => lowercase && tw`first-letter:lowercase`}
  strong {
    ${({ strongColor }) => (strongColor === 'primary' ? tw`text-primary-500` : tw`text-gray-50`)}
    ${tw`font-semibold`}
  }
`

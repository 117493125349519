import { MasterAdminTenants } from '../../components/master-admin/MasterAdminTenants'
import { MasterAdminSystemAdminList } from '../../components/master-admin/MasterAdminSystemAdminList'
import { MasterAdminSystemAdminDetails } from '../../components/master-admin/MasterAdminSystemAdminDetails'
import { MasterAdminUsersList } from '../../components/master-admin/MasterAdminUsersList'
import { MasterAdminUserDetails } from '../../components/master-admin/MasterAdminUserDetails'
import React, { useContext } from 'react'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { TFunction, useTranslation } from 'react-i18next'
import { featuresFlags } from '../../state/featuresFlags'
import { AuthInfo, UserRole } from '../../api/auth'
import { Navigate, Route, Routes } from 'react-router-dom'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { NestedRoutesProps, PageNotFound } from './SpotterRoutes'

export const MasterAdminRoutes = ({ basePath }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const links: NavbarLink[] = getMasterAdminSubLinks(authInfo, t)
  const sysAdminsPath = links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_SYSTEM_ADMINS)?.path
  const usersPath = links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_USERS)?.path

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={`${basePath}${links[0].path}`} />} />
      <Route
        path={links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_TENANTS)?.path}
        element={<MasterAdminTenants />}
      />
      <Route path={sysAdminsPath} element={<MasterAdminSystemAdminList />} />
      <Route path={`${sysAdminsPath}/:userId`} element={<MasterAdminSystemAdminDetails />} />
      <Route path={usersPath} element={<MasterAdminUsersList />} />
      <Route path={`${usersPath}/:userId`} element={<MasterAdminUserDetails />} />
      <Route path={'/*'} element={<PageNotFound />} />
    </Routes>
  )
}

export const getMasterAdminSubLinks = (authInfo: AuthInfo, t: TFunction): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.MASTER_ADMIN_TENANTS,
      label: t('subNav.masterAdmin.tenants'),
      path: '/tenants',
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    },
    {
      id: NavbarLinkId.MASTER_ADMIN_SYSTEM_ADMINS,
      label: t('subNav.masterAdmin.systemAdmins'),
      path: '/system-admins',
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    },
    {
      id: NavbarLinkId.MASTER_ADMIN_USERS,
      label: t('subNav.masterAdmin.users'),
      path: '/users',
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    }
  ]
}

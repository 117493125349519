import React, { useContext, useEffect, useState } from 'react'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { TabContent } from '../../shared/tabs/TabSharedComponents'
import { Vendor } from '../../../utils/vendors'
import { OptimizationSummaryTab } from './OptimizationSummaryTab'
import { OptimizationVendorTab } from './OptimizationVendorTab'
import {
  exportOptimizationTabData,
  getTopRecommendations,
  OptimizationCategory,
  TopRecommendations
} from '../../../api/optimization/shared'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { AwsRecommendations, getOptimizationAwsTabData } from '../../../api/optimization/aws'
import { AzureRecommendations, getOptimizationAzureTabData } from '../../../api/optimization/azure'
import { GcpRecommendations, getOptimizationGcpTabData } from '../../../api/optimization/gcp'
import { CurrencyId } from '../../../utils/Currency'
import { ExportButton } from '../../shared/buttons/ExportButton'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { Trans, useTranslation } from 'react-i18next'
import { ModalContext } from '../../../state/context/ModalContext'
import { Button, ButtonType } from '../../shared/buttons/Button'
import { ModalActions } from '../../shared/modal/Modal'
import { capitalizeFirstLetter } from '../../../utils/formats'
import { GrayText, WhiteText } from '../../shared/TextComponents'
import { CheckboxOption } from '../../shared/buttons/CheckboxOption'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { TabsContainer } from '../../shared/containers/DataContainer'

interface TabsProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedRecommendations: string[]
  selectedCurrency: CurrencyId
}

interface VendorCategory {
  vendor: Vendor
  category: OptimizationCategory
}

export const OptimizationTabs = ({
  selectedVendors,
  selectedProjects,
  selectedRecommendations,
  selectedCurrency
}: TabsProps) => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { setModal } = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const [topRecommendations, setTopRecommendations] = useState<TopRecommendations | null>(null)
  const [awsRecommendations, setAwsRecommendations] = useState<AwsRecommendations | null>(null)
  const [azureRecommendations, setAzureRecommendations] = useState<AzureRecommendations | null>(null)
  const [gcpRecommendations, setGcpRecommendations] = useState<GcpRecommendations | null>(null)
  const [vendorCategories, setVendorCategories] = useState<VendorCategory[]>([])
  const [selectedTab, setSelectedTab] = useState(Vendor.ALL)
  const isSummaryTab = selectedTab === Vendor.ALL

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getTopRecommendations(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(setTopRecommendations),
      getOptimizationAwsTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(resp => {
        setAwsRecommendations(resp)
      }),
      getOptimizationAzureTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(resp => {
        setAzureRecommendations(resp)
      }),
      getOptimizationGcpTabData(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        cancelToken.token
      ).then(resp => {
        setGcpRecommendations(resp)
      })
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => setLoading(false)
  }, [createCancelToken, handleError, selectedProjects, selectedRecommendations, selectedVendors, selectedCurrency])

  useEffect(() => {
    setAvailableCategories(
      Vendor.AWS,
      !!(
        awsRecommendations?.commitments.recommendations.reservedInstances.length ||
        awsRecommendations?.commitments.recommendations.savingsPlans.length
      ),
      !!awsRecommendations?.rightsizing.length,
      !!awsRecommendations?.utilization.length,
      vendorCategories,
      setVendorCategories
    )
  }, [awsRecommendations])

  useEffect(() => {
    setAvailableCategories(
      Vendor.AZURE,
      !!azureRecommendations?.commitments.recommendations.length,
      !!azureRecommendations?.rightsizing.length,
      !!azureRecommendations?.utilization.length,
      vendorCategories,
      setVendorCategories
    )
  }, [azureRecommendations])

  useEffect(() => {
    setAvailableCategories(
      Vendor.GCP,
      !!gcpRecommendations?.commitments.length,
      !!gcpRecommendations?.rightsizing.length,
      !!gcpRecommendations?.utilization.length,
      vendorCategories,
      setVendorCategories
    )
  }, [gcpRecommendations])

  const exportAction = () => {
    setModal({
      header: t('optimization.tabs.exportModal.header'),
      body: (
        <OptimizationExportModal
          selectedVendors={selectedVendors}
          selectedProjects={selectedProjects}
          selectedRecommendations={selectedRecommendations}
          selectedCurrency={selectedCurrency}
          availableCategories={vendorCategories}
        />
      )
    })
  }

  return (
    <TabsContainer>
      <VendorTabs
        id={'qa-optimization'}
        showSummaryTab={true}
        selectedVendor={selectedTab}
        handleSelection={setSelectedTab}
        actionComponent={
          selectedTab !== Vendor.ALL && (
            <ExportButton clickHandler={exportAction} disabled={loading || !vendorCategories.length} />
          )
        }
      />
      <TabContent verticalPadding={isSummaryTab}>
        {isSummaryTab ? (
          <OptimizationSummaryTab loading={loading} recommendations={topRecommendations} />
        ) : (
          <OptimizationVendorTab
            vendor={selectedTab}
            selectedVendors={selectedVendors}
            awsRecommendations={awsRecommendations}
            azureRecommendations={azureRecommendations}
            gcpRecommendations={gcpRecommendations}
          />
        )}
      </TabContent>
    </TabsContainer>
  )
}

interface ExportModalProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedRecommendations: string[]
  selectedCurrency: CurrencyId
  availableCategories: VendorCategory[]
}

const OptimizationExportModal = ({
  selectedVendors,
  selectedProjects,
  selectedRecommendations,
  selectedCurrency,
  availableCategories
}: ExportModalProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext(ModalContext)
  const { setMessage } = useContext(MessageContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [awsCategories, setAwsCategories] = useState<OptimizationCategory[]>([])
  const [azureCategories, setAzureCategories] = useState<OptimizationCategory[]>([])
  const [gcpCategories, setGcpCategories] = useState<OptimizationCategory[]>([])
  const [fileCount, setFileCount] = useState(0)

  useEffect(() => {
    setFileCount(awsCategories.length + azureCategories.length + gcpCategories.length)
  }, [awsCategories, azureCategories, gcpCategories])

  const clickHandler = () => {
    const cancelToken = createCancelToken()
    setLoading(true)
    exportOptimizationTabData(
      selectedVendors,
      selectedProjects,
      selectedRecommendations,
      selectedCurrency,
      awsCategories,
      azureCategories,
      gcpCategories,
      cancelToken.token
    )
      .then(() => {
        setModal(null)
        setAwsCategories([])
        setAzureCategories([])
        setGcpCategories([])
        setLoading(false)
        setMessage({
          message: t('common.downloadSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
  }

  const VendorOptions = ({ vendor }: { vendor: Vendor }) => {
    const CategoryOption = ({ category }: { category: OptimizationCategory }) => {
      const checked =
        vendor === Vendor.AWS
          ? awsCategories.includes(category)
          : vendor === Vendor.AZURE
            ? azureCategories.includes(category)
            : gcpCategories.includes(category)
      const disabled = loading || !availableCategories.find(c => c.vendor === vendor && c.category === category)

      return (
        <CheckboxOption
          type={'secondary'}
          clickHandler={() =>
            vendor === Vendor.AWS
              ? awsCategories.includes(category)
                ? setAwsCategories(awsCategories.filter(c => c !== category))
                : setAwsCategories(awsCategories.concat(category))
              : vendor === Vendor.AZURE
                ? azureCategories.includes(category)
                  ? setAzureCategories(azureCategories.filter(c => c !== category))
                  : setAzureCategories(azureCategories.concat(category))
                : gcpCategories.includes(category)
                  ? setGcpCategories(gcpCategories.filter(c => c !== category))
                  : setGcpCategories(gcpCategories.concat(category))
          }
          checked={checked}
          label={capitalizeFirstLetter(category.toLowerCase())}
          disabled={disabled}
        />
      )
    }

    return (
      <div className={'flex flex-col w-full items-center gap-4 px-7 first:pl-2 last:pr-2'}>
        <WhiteText className={'font-semibold'}>{t(`vendors.${vendor}.short`)}</WhiteText>
        <div className={'flex flex-col items-center gap-2'}>
          <CategoryOption category={OptimizationCategory.COMMITMENTS} />
          <CategoryOption category={OptimizationCategory.RIGHTSIZING} />
          <CategoryOption category={OptimizationCategory.UTILIZATION} />
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`flex divide-x divide-gray-500 pt-3 ${userSettings.visibleVendors.length === 1 ? 'justify-center' : 'justify-between'}`}
      >
        {userSettings.visibleVendors.map(vendor => (
          <VendorOptions vendor={vendor} key={vendor} />
        ))}
      </div>
      <GrayText className={'first-letter:capitalize text-90 w-full text-center pt-6'}>
        <Trans>{fileCount > 1 && t('optimization.tabs.exportModal.fileCount', { count: fileCount })}</Trans>
      </GrayText>
      <ModalActions>
        <Button
          value={t('common.export')}
          type={ButtonType.FORM}
          clickHandler={clickHandler}
          disabled={!fileCount || loading}
        />
      </ModalActions>
    </>
  )
}

const setAvailableCategories = (
  vendor: Vendor,
  hasCommitments: boolean,
  hasRightsizing: boolean,
  hasUtilization: boolean,
  vendorCategories: VendorCategory[],
  setVendorCategories: (categories: VendorCategory[]) => void
): VendorCategory[] => {
  let updatedCategories = vendorCategories.filter(c => c.vendor !== vendor)

  if (hasCommitments) {
    updatedCategories = updatedCategories.concat({
      vendor: vendor,
      category: OptimizationCategory.COMMITMENTS
    })
  }

  if (hasRightsizing) {
    updatedCategories = updatedCategories.concat({
      vendor: vendor,
      category: OptimizationCategory.RIGHTSIZING
    })
  }

  if (hasUtilization) {
    updatedCategories = updatedCategories.concat({
      vendor: vendor,
      category: OptimizationCategory.UTILIZATION
    })
  }

  setVendorCategories(updatedCategories)
  return updatedCategories
}

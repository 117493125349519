import { AxiosResponse, CancelToken } from 'axios'
import { client } from './client'
import { CloudProject } from '../utils/classes'
import { Vendor } from '../utils/vendors'
import { CurrencyId } from '../utils/Currency'
import { CostAnomalyLimitData } from './settings/notifications'

export const getCostAnomalyNotifications = async (cancelToken: CancelToken): Promise<CostAnomalyNotification[]> => {
  const resp = await client.get<CostAnomalyNotification[]>('notifications/cost-anomalies', { cancelToken })
  return resp.data
}

export const updateCostAnomalyNotificationsStatus = async (
  notifications: CostAnomalyNotification[],
  status: NotificationState,
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  const resp = await client.put<CostAnomalyNotification[]>(
    `notifications/cost-anomalies/mark-as-${status}`,
    { notifications: notifications },
    {
      cancelToken
    }
  )
  return resp.data
}

export const moveCostAnomalyNotificationsToBin = async (
  notifications: CostAnomalyNotification[],
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  const resp = await client.put<CostAnomalyNotification[]>(
    `notifications/cost-anomalies/move-to-bin`,
    { notifications: notifications },
    {
      cancelToken
    }
  )
  return resp.data
}

export const restoreCostAnomalyNotificationsToInbox = async (
  notifications: CostAnomalyNotification[],
  cancelToken: CancelToken
): Promise<CostAnomalyNotification[]> => {
  const resp = await client.put<CostAnomalyNotification[]>(
    `notifications/cost-anomalies/restore-to-inbox`,
    { notifications: notifications },
    {
      cancelToken
    }
  )
  return resp.data
}

export const deleteCostAnomalyNotificationsPermanently = async (
  notifications: CostAnomalyNotification[]
): Promise<CostAnomalyNotification[]> => {
  const resp: AxiosResponse<CostAnomalyNotification[]> = await client({
    method: 'delete',
    url: 'notifications/cost-anomalies',
    data: {
      notifications
    }
  })

  return resp.data
}

export interface CostAnomalyNotification {
  vendor: Vendor
  notificationId: string
  project: CloudProject
  service: string
  lastMonthDailyCost: number
  currentMonthDailyCost: number
  estTotalCosts: number
  currency: CurrencyId
  changePercent: number
  monthlyImpact: number
  date: Date
  anomalyState: CostAnomalyState
  notificationState: NotificationState
  anomalyLimits: CostAnomalyLimitData
  projectLimits: CostAnomalyLimitData
  serviceLimits: CostAnomalyLimitData
  emailSubscriptions: CostAnomalyEmailSubscriptionOptions | null
  deletedAt: Date | null
  disabledAt: Date | null
}

interface CostAnomalyEmailSubscriptionOptions {
  project: boolean
  service: boolean
}

export interface DisabledCostNotificationData {
  vendor: Vendor
  project: CloudProject
  disabledAt?: Date
}

export enum NotificationState {
  NEW = 'NEW',
  UNREAD = 'UNREAD',
  READ = 'READ'
}

export enum CostAnomalyState {
  INCREASING = 'INCREASING',
  INCREASING_MORE = 'INCREASING_MORE',
  RECOVERING_FROM_INCREASE = 'RECOVERING_FROM_INCREASE',
  DECREASING = 'DECREASING',
  DECREASING_MORE = 'DECREASING_MORE',
  RECOVERED = 'RECOVERED'
}

export enum ChangeType {
  INCREASING = 'INCREASING',
  DECREASING = 'DECREASING'
}

export const INCREASING_COST_ANOMALY_STATES = [
  CostAnomalyState.INCREASING,
  CostAnomalyState.INCREASING_MORE,
  CostAnomalyState.RECOVERING_FROM_INCREASE
]

export const DECREASING_COST_ANOMALY_STATES = [CostAnomalyState.DECREASING, CostAnomalyState.DECREASING_MORE]

import { useEffect } from 'react'

export const useBlurOnEnter = (elementId?: string) => {
  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (elementId && e.key === 'Enter') {
        document.getElementById(elementId)?.blur()
      }
    })

    return () => {
      document.removeEventListener('keydown', e => {
        if (elementId && e.key === 'Enter') {
          document.getElementById(elementId)?.blur()
        }
      })
    }
  }, [elementId])
}

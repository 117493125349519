import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../CustomIcon'
import close from '../../../assets/svg/actions/close-heavy.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useRef, useState } from 'react'
import magnifyingGlass from '../../../assets/svg/objects/magnifying-glass.svg'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { CapitalizedText, GrayText } from '../TextComponents'

export type SearchInputProps = {
  placeholder?: string
  searchText: string
  setSearchText: (text: string) => void
  id?: string
  size?: 'small' | 'default'
} & (
  | {
      useTooltip: true
      tooltipText: string
      tooltipPosition: {
        x: number
        y: number
      }
    }
  | {
      useTooltip?: false
      tooltipText?: never
      tooltipPosition?: never
    }
)

export const SearchInput = ({
  placeholder,
  searchText,
  setSearchText,
  id,
  size = 'default',
  useTooltip = false,
  tooltipPosition,
  tooltipText
}: SearchInputProps) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [focused, setFocused] = useState(false)
  const inputId = `${id}-search-input`

  const displayTooltip = () => {
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), 5000)
  }

  return (
    <>
      <InputContainer
        ref={ref}
        size={size}
        onMouseMove={() => displayTooltip()}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => document.getElementById(inputId)?.focus()}
        focused={focused}
      >
        <CustomIcon
          styles={`bg-gray-200 hover:bg-gray-50 ${size === 'small' ? 'h-5 w-5 min-w-5' : 'w-6 h-6'}`}
          iconType={IconType.VECTOR}
          path={magnifyingGlass}
        />
        <div className={`flex items-center gap-1.5 w-full`}>
          <input
            id={inputId}
            type={'text'}
            placeholder={placeholder ? placeholder : t('common.search')}
            onChange={event => setSearchText(event.target.value)}
            value={searchText}
            className={'w-full outline-none'}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
          <CustomIcon
            styles={`w-4 h-4 ${searchText !== '' ? 'bg-gray-200 hover:bg-gray-50' : 'bg-transparent'}`}
            iconType={IconType.VECTOR}
            path={close}
            onClick={() => setSearchText('')}
          />
        </div>
      </InputContainer>
      {useTooltip && tooltipPosition && showTooltip && (
        <InfoTooltip x={tooltipPosition.x} y={tooltipPosition.y}>
          <CapitalizedText>{tooltipText}</CapitalizedText>
        </InfoTooltip>
      )}
    </>
  )
}

interface ContainerProps {
  size: 'small' | 'default'
  focused?: boolean
}

const InputContainer = styled.div<ContainerProps>`
  ${tw`flex items-center text-gray-100 justify-center w-full max-w-124 p-1.5 transition-all ease-in-out duration-200 cursor-pointer bg-gray-700/60 border rounded gap-1.5 py-1.5 px-2 border-gray-400/60 hover:shadow-input-focus`}
  ${({ size }) => size === 'small' && tw`p-1 min-w-[34px] min-h-[34px] max-h-[34px]`}
  ${({ focused }) => focused && tw`border-gray-400 shadow-input-focus`}
  input {
    ${tw`text-90 bg-transparent`}
    ${({ size }) => size === 'small' && tw`text-90`}
  }
  input::placeholder {
    ${tw`text-gray-300`}
  }
`

interface LabeledSearchInputProps {
  label: string
  placeholder: string
  searchText: string
  setSearchText: (searchText: string) => void
}

export const LabeledSearchInput = ({ label, placeholder, searchText, setSearchText }: LabeledSearchInputProps) => {
  return (
    <div>
      <GrayText className={'text-90'}>{label}</GrayText>
      <SearchInput placeholder={placeholder} searchText={searchText} setSearchText={setSearchText} />
    </div>
  )
}

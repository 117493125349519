import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserRole } from '../../api/auth'
import { Costs } from '../../components/costs/Costs'
import { Compliance } from '../../components/compliance/Compliance'
import { featuresFlags } from '../../state/featuresFlags'
import { Tickets } from '../../components/tickets/Tickets'
import { ConsentAzure } from '../../components/login/ConsentAzure'
import { FederatedAuth, Login } from '../../components/login/Login'
import { Home } from '../../components/home/Home'
import { Sustainability } from '../../components/sustainability/Sustainability'
import { SystemAdmin } from '../../components/SystemAdmin'
import { Infra } from '../../components/infra/Infra'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'
import { Optimization } from '../../components/optimization/Optimization'
import { SignUp } from '../../components/login/SignUp'
import { ContactSupport } from '../../components/ContactSupport'
import { Layout } from '../Layout'
import { MasterAdminRoutes } from './MasterAdminRoutes'
import { SettingsRoutes } from './SettingsRoutes'
import { AdminRoutes } from './AdminRoutes'
import { ModalContext } from '../../state/context/ModalContext'

export interface NestedRoutesProps {
  basePath: string
}

const SpotterRoutes = () => {
  const { authInfo } = useContext(UserInfoContext)
  const location = useLocation()
  const navigate = useNavigate()
  const { setModal } = useContext(ModalContext)
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))

  useEffect(() => {
    !location.pathname.includes('/integrations') &&
      !location.pathname.includes('/profile') &&
      !authInfo.hasIntegrations &&
      isAdmin &&
      navigate('/admin/integrations/new')

    location.pathname.includes('/profile') && !authInfo.hasIntegrations && setModal(null)
  }, [authInfo, location])

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          authInfo.isLoggedIn ? <Navigate to={'/home'} replace={true} /> : <Navigate to={'/login'} replace={true} />
        }
      />
      <Route path={'/signup'} element={<SignUp />} />
      <Route path={`/aws-marketplace/signup/:tenantId`} element={<SignUp />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/federated-auth'} element={<FederatedAuth />} />
      <Route path={'/azure-admin-consent'} element={<ConsentAzure />} />
      <Route
        path={'/logout'}
        element={
          <>
            <Navigate to={'/login'} replace={true} />
            <Login />
          </>
        }
      />

      {featuresFlags.home && authInfo.isLoggedIn && <Route path={'/home'} element={<Home />} />}
      {featuresFlags.costs && authInfo.costsAccess && <Route path={'/costs'} element={<Costs />} />}
      {featuresFlags.optimization && authInfo.optimizationAccess && (
        <Route path={'/optimization'} element={<Optimization />} />
      )}
      {featuresFlags.compliance && authInfo.complianceAccess && <Route path={'/compliance'} element={<Compliance />} />}
      {featuresFlags.tickets && authInfo.ticketAccess && authInfo.freshIntegration && (
        <Route path={'/tickets'} element={<Tickets />} />
      )}
      {featuresFlags.infra && authInfo.infraAccess && <Route path={'/infra'} element={<Infra />} />}
      {featuresFlags.sustainability && authInfo.sustainabilityAccess && (
        <Route path={'/sustainability'} element={<Sustainability />} />
      )}
      {featuresFlags.admin && isAdmin && <Route path={'/admin/*'} element={<AdminRoutes basePath={'/admin'} />} />}
      {featuresFlags.systemAdmin &&
        authInfo.roles.some(role => role === UserRole.SYSTEM_ADMIN || role === UserRole.MASTER_ADMIN) && (
          <Route path={'/system-admin'} element={<SystemAdmin />} />
        )}
      {featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN) && (
        <Route path={'/master-admin/*'} element={<MasterAdminRoutes basePath={'/master-admin'} />} />
      )}
      <Route path={'/settings/*'} element={<SettingsRoutes basePath={'/settings'} />} />
      <Route path={'/support'} element={<ContactSupport />} />

      <Route path={'*'} element={authInfo.isLoggedIn ? <PageNotFound /> : <Navigate to={'/login'} replace={true} />} />
    </Routes>
  )
}

export const PageNotFound = () => {
  const { t } = useTranslation()
  return <Layout>{t('error.routeNotFound')}</Layout>
}

export default SpotterRoutes

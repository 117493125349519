import { client } from '../client'
import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { PermittedVendorProject } from '../admin/users'
import { CurrencyId } from '../../utils/Currency'
import { CostAnomalyLimitData } from './notifications'
import { AuthInfo } from '../auth'

export const getUserContextData = async (cancelToken: CancelToken): Promise<UserContextData> => {
  return await client.get<UserContextData>('/user-data', { cancelToken }).then(resp => ({
    authInfo: {
      ...resp.data.authInfo,
      site247Integration: resp.data.authInfo.site247Integration
        ? new Date(resp.data.authInfo.site247Integration)
        : undefined,
      freshIntegration: resp.data.authInfo.freshIntegration ? new Date(resp.data.authInfo.freshIntegration) : undefined,
      lastLoginAt: resp.data.authInfo.lastLoginAt ? new Date(resp.data.authInfo.lastLoginAt) : null,
      isLoggedIn: true
    },
    userSettings: resp.data.userSettings,
    integrationStates: resp.data.integrationStates
  }))
}

export const getUserPermittedProjects = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<PermittedVendorProject[]> => {
  return await client
    .get<PermittedVendorProject[]>(`settings/permitted-projects/${vendor.toLowerCase()}`, { cancelToken })
    .then(resp => resp.data)
}

export const changeUserPreferredCurrency = async (
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<UserSettings> => {
  return await client.post('settings/currency', currency, { cancelToken }).then(resp => resp.data)
}

export const sendContactSupportForm = async (
  contactForm: ContactSupportForm,
  cancelToken: CancelToken
): Promise<void> => {
  return await client.post('/contact-support', contactForm, { cancelToken }).then(() => {})
}

export const getIntegrationStates = async (cancelToken: CancelToken): Promise<TenantIntegrationStates> => {
  return await client.get('/integration-states', { cancelToken }).then(resp => resp.data)
}

export interface UserSettings {
  currency: CurrencyId
  defaultCostAnomalyLimits: CostAnomalyLimitData
  visibleVendors: Vendor[]
}

export interface ContactSupportForm {
  category: ContactCategory
  subject: string
  message: string
}

export enum ContactCategory {
  BUG_REPORT = 'BUG_REPORT',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  OTHER = 'OTHER'
}

export interface TenantIntegrationStates {
  aws: AwsIntegrationStates[] | null
  azure: AzureIntegrationStates[] | null
  gcp: GcpIntegrationStates[] | null
}

interface AwsIntegrationStates {
  masterAccount: string
  configAccount: string
  securityAccount: string
  lastOrganizationIntegrationAt: Date | null
  lastResourceIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

interface AzureIntegrationStates {
  azureTenantId: string
  lastOrganizationIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

interface GcpIntegrationStates {
  organizationId: string
  billingAccount: string
  lastOrganizationIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

export interface UserContextData {
  authInfo: AuthInfo
  userSettings: UserSettings
  integrationStates: TenantIntegrationStates
}

export const hasIntegrationErrors = (
  states: AwsIntegrationStates[] | AzureIntegrationStates[] | GcpIntegrationStates[] | null
) =>
  states?.some(
    i =>
      !i.lastOrganizationIntegrationAt ||
      ('lastResourceIntegrationAt' in i && !i.lastResourceIntegrationAt) ||
      !i.lastBillingIntegrationAt ||
      !i.lastComplianceIntegrationAt ||
      !i.lastRecommendationIntegrationAt ||
      !i.lastUsageIntegrationAt
  ) || false

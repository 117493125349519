import { TFunction, useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import React, { useContext } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { UserList } from '../../components/admin/users/UserList'
import { UserDetails } from '../../components/admin/users/UserDetails'
import { AdminIntegrations, NewIntegration } from '../../components/admin/integrations/AdminIntegrations'
import { Vendors } from '../../utils/vendors'
import { AwsIntegrationDetails } from '../../components/admin/integrations/aws/AwsIntegrationDetails'
import { AzureIntegrationDetails } from '../../components/admin/integrations/azure/AzureIntegrationDetails'
import { GcpIntegrationDetails } from '../../components/admin/integrations/gcp/GcpIntegrationDetails'
import { TenantCostAnomalyRules } from '../../components/admin/alerts/TenantCostAnomalyRules'
import { TenantCostAnomalyRuleDetails } from '../../components/admin/alerts/TenantCostAnomalyRuleDetails'
import { hasIntegrationErrors, TenantIntegrationStates } from '../../api/settings/profile'
import { PingType } from '../../components/shared/tabs/Tab'
import { UserRole } from '../../api/auth'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'
import { NestedRoutesProps, PageNotFound } from './SpotterRoutes'

export const AdminRoutes = ({ basePath }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const links = getAdminSubLinks(t, authInfo.roles, integrationStates)
  const usersPath = links.find(link => link.id === NavbarLinkId.ADMIN_USERS)?.path
  const integrationsPath = links.find(link => link.id === NavbarLinkId.ADMIN_INTEGRATIONS)?.path
  const alertsPath = links.find(link => link.id === NavbarLinkId.ADMIN_ALERTS)?.path

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={`${basePath}${links[0].path}`} />} />
      <Route path={usersPath} element={<UserList />} />
      <Route path={`${usersPath}/:userId`} element={<UserDetails />} />
      <Route
        path={integrationsPath}
        element={
          authInfo.awsAccess && integrationStates.aws ? (
            <Navigate to={`${basePath}${integrationsPath}/aws`} />
          ) : authInfo.azureAccess && integrationStates.azure ? (
            <Navigate to={`${basePath}${integrationsPath}/azure`} />
          ) : authInfo.gcpAccess && integrationStates.gcp ? (
            <Navigate to={`${basePath}${integrationsPath}/gcp`} />
          ) : (
            <Navigate to={`${basePath}${integrationsPath}/new`} />
          )
        }
      />
      {Vendors.map(vendor => (
        <Route
          key={vendor}
          path={`${integrationsPath}/${vendor.toLowerCase()}`}
          element={<AdminIntegrations selectedVendor={vendor} />}
        />
      ))}
      <Route path={`${integrationsPath}/new`} element={<NewIntegration />} />
      <Route path={`${integrationsPath}/aws/:masterAccountId`} element={<AwsIntegrationDetails />} />
      <Route path={`${integrationsPath}/azure/:azureTenantId`} element={<AzureIntegrationDetails />} />
      <Route path={`${integrationsPath}/gcp/:organizationId`} element={<GcpIntegrationDetails />} />
      <Route path={alertsPath} element={<Navigate to={`${basePath}${alertsPath}/costs`} />} />
      <Route path={`${alertsPath}/costs`} element={<TenantCostAnomalyRules />} />
      <Route path={`${alertsPath}/costs/:ruleId`} element={<TenantCostAnomalyRuleDetails />} />
      <Route path={'/*'} element={<PageNotFound />} />
    </Routes>
  )
}

export const getAdminSubLinks = (
  t: TFunction,
  userRoles: UserRole[],
  integrationStates: TenantIntegrationStates
): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.ADMIN_USERS,
      label: t('admin.subnav.users'),
      path: '/users',
      display: userRoles.some(role => ADMIN_ROLES.includes(role))
    },
    {
      id: NavbarLinkId.ADMIN_INTEGRATIONS,
      label: t('admin.subnav.integrations'),
      path: '/integrations',
      display: userRoles.some(role => ADMIN_ROLES.includes(role)),
      ping:
        (hasIntegrationErrors(integrationStates.aws) ||
          hasIntegrationErrors(integrationStates.azure) ||
          hasIntegrationErrors(integrationStates.gcp)) &&
        PingType.ERROR
    },
    {
      id: NavbarLinkId.ADMIN_ALERTS,
      label: t('admin.subnav.alerts.mainPath'),
      path: '/alerts',
      display: userRoles.some(role => role === UserRole.ADMIN),
      links: [
        {
          id: NavbarLinkId.ADMIN_ALERTS_COSTS,
          label: t('admin.subnav.alerts.costs'),
          path: '/alerts/costs',
          display: userRoles.some(role => role === UserRole.ADMIN)
        }
      ]
    }
  ]
}
